import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Banner from "./RefundPolicyBanner";
import axiosClient from "../../axios-client";

function PrivacyPolicy() {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchRefundPolicy = async () => {
      try {
        const response = await axiosClient.get("/refund-policy");
        setContent(response.data.refundPolicy.content || "");
      } catch (error) {
        console.error("Error fetching refund policy:", error);
      }
    };

    fetchRefundPolicy();
  }, []);
  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />
      {/* <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 mx-auto max-w-screen-2xl font-mont">
        <h1 className="md:text-3xl text-2xl font-bold mb-6">Refund Policy</h1>
        <p
          className="md:pb-6 pb-4"
          style={{ lineHeight: "2", textAlign: "justify" }}
        >
          At Dr. Palve Hospital, we strive to offer the best diagnostic services. If for any reason you are not satisfied or need to cancel a service, the following refund policies apply.
        </p>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
        1. Eligibility for Refund
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
           Refunds are applicable under the following circumstances:
          </p>
          <ul className="list-disc pl-6">
            <li className="mt-1">
            The diagnostic test was canceled before the sample collection or appointment.
            </li>
            <li className="mt-1">
            There was a significant delay or failure in delivering the service.
            </li>
            <li className="mt-1">
            The service provided was incomplete or incorrect.
            </li>
          </ul>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4 mt-4">
        2. Refund Process
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            To request a refund, you must:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
            Contact us within 24 Hours of the scheduled service.
            </li>
            <li className="mt-1">
            Provide your booking details, including your order number and reason for cancellation.
            </li>
          </ul>
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
           Our team will review the request and confirm eligibility for a refund. Refunds will be issued using the original payment method. The refund process may take up to 7-10 business days depending on your payment provider.
          </p>
        </div>
        
        <h3 className="md:text-xl text-lg font-semibold mb-4">
        3. Non-Refundable Services
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
           The following cases are generally not eligible for a refund:
          </p>
          <ul className="list-disc pl-6">
            <li className="mt-1">
            The test was conducted successfully and results have been delivered.
            </li>
            <li className="mt-1">
            Cancellations made after the sample has been collected.
            </li>
            <li className="mt-1">
            Errors resulting from the incorrect information provided by the patient.
            </li>
          </ul>
        </div>
        
        <h3 className="md:text-xl text-lg font-semibold mb-4 mt-4">
        4. Partial Refunds
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            In certain cases, a partial refund may be granted, for example, when only part of the service was completed. In such instances, the refund will reflect the unused portion of the service.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
        5. Delayed or Missing Refunds
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
           If you haven’t received your refund after the stated processing time, please:
          </p>
          <ul className="list-disc pl-6">
            <li className="mt-1">
            Check with your bank or payment provider first.
            </li>
            <li className="mt-1">
            Contact us if the issue persists, and we will assist you in tracking the refund.
            </li>
          </ul>
        </div>
        
        <h3 className="md:text-xl text-lg font-semibold mb-4 mt-4">
        6. Changes to the Refund Policy
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            We reserve the right to update this policy at any time. Changes will be posted on this page, and your continued use of our services indicates your acceptance of the modified terms.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          7. Contact Information
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            If you have any questions or concerns regarding this Privacy Policy,
            or if you would like to file a complaint, please contact us at:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Email: </span>
              <span>
                <a
                  href="mailto:support@drpalvehospital.com"
                  className="text-blue-500"
                >
                  support@drpalvehospital.com
                </a>
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Phone: </span>
              <span>081492 02506</span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Address: </span>
              <span>
                Dr Palve Multispecialty Hospital, Chandan Nagar, Kharadi, Pune,
                Maharashtra - 411014.
              </span>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 mx-auto max-w-screen-2xl font-mont">
        <div
          className="terms-content"
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
