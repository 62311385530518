import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";

const TestCategory = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const { filepath } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("test-categories/test/categories");
      setCategories(response.data.testCategory || []);
      setLoading(false);
      setError("");
    } catch (err) {
      setError("Failed to fetch test categories.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [categories]);

  const handleShow = (categoryId) => {
    setSelectedCategory(categoryId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedCategory(null);
  };

  // Handle delete category
  const handleDelete = async () => {
    try {
      await axiosClient.delete(
        `test-categories/test/category/${selectedCategory}`
      );
      setCategories(
        categories.filter((category) => category._id !== selectedCategory)
      );
      setSuccess("Test category deleted successfully!");
      setError("");
      handleClose();
      fetchCategories();
    } catch (err) {
      setError("Failed to delete test category.");
      setSuccess("");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Test Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this test category?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Test Category</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <div className="add-button">
                <Link to="/admin/add-test-category">
                  <button> Add Test Category</button>
                </Link>
              </div>
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Test Category</th>
                      <th>Total Test</th>
                      <th>Test Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.length > 0 ? (
                      categories.map((category, index) => (
                        <tr key={category._id}>
                          <td>{index + 1}</td>
                          <td>{category.name}</td>
                          <td>{category.TotalTest}</td>
                          <td>
                            <img
                              src={`${filepath}${category.TestImage}`}
                              alt="img"
                              width="50px"
                              height="50px"
                            />
                          </td>
                          <td>
                            <Link
                              to={`/admin/edit-test-category/${category._id}`}
                            >
                              <i className="fa-regular fa-pen-to-square"></i>
                            </Link>{" "}
                            &nbsp;
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(category._id)}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No categories available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestCategory;
