import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar2 from "../layout/Navbar2";
import Footer from "../layout/Footer";
import NewsLetter from "../landingPage/NewsLetter";
import axiosClient from "../../axios-client";

const PaymentStatus = () => {
  const merchantTransactionId = localStorage.getItem("TransactionID");
  const bookingId = localStorage.getItem("bookingId");
  const userID = localStorage.getItem("userId");
  const muid = localStorage.getItem("muid");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  // console.log(merchantTransactionId);

  useEffect(() => {
    // Fetch the transaction status from your backend
    setLoading(true);
    const checkPaymentStatus = async () => {
      try {
        const response = await axiosClient.get(
          `/payment/transactionStatus/${merchantTransactionId}`
        );
        // console.log(response);
        // console.log(response.data);

        if (response.data.code === "PAYMENT_SUCCESS") {
          //////////////////////
          const userDetail = await axiosClient.get(`/users/details/${userID}`);
          const FName = userDetail.data.user.firstName;
          const LName = userDetail.data.user.lastName;
          const FullName = FName + " " + LName;
          const MNumber = userDetail.data.user.phone;

          /////////////////
          const BookingDetails = await axiosClient.get(
            `/tests-booking/test/booking/${bookingId}`
          );
          // console.log("bookig detail fetched", BookingDetails);
          const data = {
            MUID: muid,
            transactionId: merchantTransactionId,
            serviceType: "",
            paymentMethod: "Online",
            paymentStatus: "Success",
            bookingStatus: "Confirmed",
            testDate: BookingDetails.data.booking.testDate,
            timeSlot: BookingDetails.data.booking.timeSlot,
            test_id: BookingDetails.data.booking.test_id,
            familyMembers_id: BookingDetails.data.booking.familyMembers_id,
            isHome: BookingDetails.data.booking.isHome,
            homeAddress: BookingDetails.data.booking.homeAddress,
            labAddress: BookingDetails.data.booking.labAddress,
            physicalCopy: BookingDetails.data.booking.physicalCopy,
            totalAmount: BookingDetails.data.booking.totalAmount,
          };
          const bookingResponse = await axiosClient.put(
            `tests-booking/test/booking/${bookingId}`,
            data
          );
          // console.log("booking data is update", bookingResponse);
          if (bookingResponse.status === 200) {
            const StorePayment = {
              name: FullName,
              amount: BookingDetails.data.booking.totalAmount,
              transactionId: merchantTransactionId,
              merchantUserId: muid,
              bookingId: bookingId,
              mobileNumber: MNumber,
              status: "Success",
            };
            const paymentResponse = await axiosClient.post(
              `payment/create/payments`,
              StorePayment
            );
            // console.log("paymentResponse data is stored", paymentResponse);
          }
          setStatus("success");
        } else {
          setStatus("failed");
        }
      } catch (error) {
        console.error(error);
        setStatus("error");
      } finally {
        setLoading(false);
        // localStorage.removeItem("bookingId");
        // localStorage.removeItem("TransactionID");
      }
    };
  
    checkPaymentStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="payment-status">
        <Navbar2 />
        {status === "success" ? (
          <div className="flex items-center justify-center bg-white mt-20 mb-20 p-4">
            <div className="max-w-[990px] w-full h-[600px] bg-[#CEE0FF] rounded-lg shadow-lg flex flex-col items-center justify-center">
              {/* Tick Image */}
              <div className="flex items-center justify-center mb-4">
                <img
                  src="./images/maximising-user-satisfaction-1-removebg-preview 1.png"
                  alt="Success"
                  className="w-3/4 h-auto max-w-[240px] md:max-w-[300px] -mt-16" // Responsive image size
                />
              </div>

              {/* Text Content */}
              <div className="text-center mt-2">
                <h1 className="text-3xl md:text-4xl font-bold text-[#2E3192] font-mont">
                  Congratulations !!
                </h1>
                <p className="text-[#636060] mt-4 font-museo font-semibold text-base md:text-lg">
                  Your payment was successful
                </p>
              </div>

              {/* Button */}
              <Link to="/">
                <button className="mt-10 bg-[#DF4132] text-white py-2 px-8 rounded-lg hover:bg-[#e04e4e] focus:outline-none focus:ring-2 focus:ring-[#FF5F5F] font-poppins text-base md:text-lg">
                  Go to Dashboard
                </button>
              </Link>
            </div>
          </div>
        ) : status === "failed" ? (
          <div className="flex items-center justify-center bg-white mt-20 mb-20 p-4">
            <div className="max-w-[990px] w-full h-[600px] bg-[#CEE0FF] rounded-lg shadow-lg flex flex-col items-center justify-center">
              {/* Tick Image */}
              <div className="flex items-center justify-center mb-4">
                <img
                  src="./images/transFaild.png"
                  alt="Success"
                  className="w-3/4 h-auto max-w-[240px] md:max-w-[300px] -mt-16" // Responsive image size
                />
              </div>

              {/* Text Content */}
              <div className="text-center mt-2">
                <h1 className="text-3xl md:text-4xl font-bold text-[#2E3192] font-mont">
                  SORRY !!
                </h1>
                <p className="text-[#636060] mt-4 font-museo font-semibold text-base md:text-lg">
                  Your payment was Faild
                </p>
              </div>

              {/* Button */}
              <Link to="/">
                <button className="mt-10 bg-[#DF4132] text-white py-2 px-8 rounded-lg hover:bg-[#e04e4e] focus:outline-none focus:ring-2 focus:ring-[#FF5F5F] font-poppins text-base md:text-lg">
                  Go to Dashboard
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <h1>Error</h1>
            <p>There was an issue retrieving your payment status.</p>
          </div>
        )}
        <NewsLetter />
        <Footer />
      </div>
    </>
  );
};

export default PaymentStatus;
