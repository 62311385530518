import React from 'react';
import { Card, Table } from 'react-bootstrap';

const FamilyMembersDetails = ({ bookingFamilyMemberId, userFamilyMembers }) => {
  // Filter family members based on booking's familyMembers_id
  const selectedFamilyMembers = userFamilyMembers?.filter(member => 
    bookingFamilyMemberId.includes(member._id)
  );

  return (
    <Card className="mb-4 shadow-sm">
      <Card.Header className="bg-light">
        <div className="d-flex justify-content-between align-items-center text-lg font-bold">
          Family Members
        </div>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead className="table-light">
              <tr>
                <th className="text-center" style={{ width: '60px' }}>S.No.</th>
                <th>Name</th>
                <th className="text-center" style={{ width: '80px' }}>Age</th>
                <th>Gender</th>
                <th>Relation</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {selectedFamilyMembers?.map((member, index) => (
                <tr key={member._id}>
                  <td className="text-center">{index + 1}</td>
                  <td>
                    <span className="fw-medium">{member.name}</span>
                  </td>
                  <td className="text-center">{member.age}</td>
                  <td>
                    {member.gender.charAt(0).toUpperCase() + member.gender.slice(1)}
                  </td>
                  <td>
                    <span className="text-capitalize">{member.relation}</span>
                  </td>
                  <td>
                    <a 
                      href={`tel:${member.phone}`} 
                      className="text-decoration-none"
                    >
                      {member.phone}
                    </a>
                  </td>
                  <td>
                    <a 
                      href={`mailto:${member.email}`}
                      className="text-decoration-none text-truncate d-inline-block"
                      style={{ maxWidth: '200px' }}
                    >
                      {member.email}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FamilyMembersDetails;