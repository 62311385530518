import React from "react";
import { useNavigate } from "react-router-dom";

const HomeTestingSteps = () => {
  const navigate = useNavigate();

  const steps = [
    {
      id: 1,
      title: "Book a Test",
      description: "Use search or package selector to book a test",
      path: "/book-test", // Route path for this step
    },
    {
      id: 2,
      title: "Sample Collection",
      description:
        "Choose a convenient time slot and address for smooth sample collection",
      path: "/sample-collection", // Route path for this step
    },
    {
      id: 3,
      title: "Download Reports",
      description: "Download test reports with one click",
      path: "/download-reports", // Route path for this step
    },
    {
      id: 4,
      title: "Consultation",
      description: "Avail report consultation once your reports are ready",
      path: "/consultation", // Route path for this step
    },
  ];

  // const handleCardClick = (path) => {
  //   navigate(path);
  // };

  return (
    <div className="max-w-screen-2xl mx-auto px-4 sm:px-8 lg:px-20 mt-10">
      {/* Title */}
      <h2 className="text-center text-2xl sm:text-2xl lg:text-3xl font-bold font-museo md:mb-8 py-10 ">
        Easy Home Testing in <span className="font1">4 Steps</span>
      </h2>

      {/* Steps */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {steps.map((step) => (
          <div
            key={step.id}
            className="bg-card1 text-white rounded-2xl lg:p-6 px-3 py-4 shadow-2xl font-museo flex flex-col items-center text-center cursor-pointer hover-color1 transition"
            // onClick={() => handleCardClick(step.path)} // Redirect on click
          >
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white font6 font-semibold text-lg mb-4">
              {step.id}
            </div>
            <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-2">
              {step.title}
            </h3>
            <p className="text-sm sm:text-base font-mont opacity-80">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeTestingSteps;
