import React, { useState } from "react";

const Description = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const isLong = typeof text === "string" && text.length > 110;
  const displayText =
    isExpanded || !isLong ? text : `${text.substring(0, 110)}...`;

  return (
    <p className="font7 mt-2 font-mont md:text-base text-sm">
      {displayText}
      {isLong && (
        <span onClick={toggleReadMore} className="font6 cursor-pointer ml-1">
          {isExpanded ? " Read less" : " Read more"}
        </span>
      )}
    </p>
  );
};

export default Description;
