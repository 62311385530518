import React, { useState, useEffect } from "react";
import { Card, Badge, Spinner, Alert } from "react-bootstrap";
import { Banknote } from "lucide-react";
import ReportHandler from "./ReportHandler";

const TestDetails = ({ testIds, axiosClient, id }) => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch all tests in parallel
        const testPromises = testIds?.map((id) =>
          axiosClient
            .get(`/tests/test/${id}`)
            .then((response) => response.data.test)
            .catch((error) => {
              console.error(`Failed to fetch test ${id}:`, error);
              return null;
            })
        );

        const results = await Promise.all(testPromises);
        const validTests = results.filter((test) => test !== null);
        setTests(validTests);
      } catch (err) {
        setError("Failed to fetch test details. Please try again later.");
        console.error("Error fetching tests:", err);
      } finally {
        setLoading(false);
      }
    };

    if (testIds && testIds?.length > 0) {
      fetchTests();
    } else {
      setLoading(false);
    }
  }, [testIds]);

  if (loading) {
    return (
      <Card className="mb-4 shadow-sm">
        <Card.Body className="text-center py-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading tests...</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Alert variant="danger" className="mb-0">
            {error}
          </Alert>
        </Card.Body>
      </Card>
    );
  }

  if (!tests.length) {
    return (
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Alert variant="info" className="mb-0">
            No test information available
          </Alert>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-4 shadow-sm">
      <Card.Header className="bg-light">
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-lg font-bold">Test Details</span>
          <Badge bg="primary">
            {tests.length} {tests.length === 1 ? "Test" : "Tests"}
          </Badge>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="test-list">
          {tests.map((test, index) => (
            <div
              key={test._id}
              className={`test-item ${
                index !== tests.length - 1 ? "border-bottom" : ""
              }`}
              style={{
                paddingBottom: index !== tests.length - 1 ? "1rem" : "0",
                marginBottom: index !== tests.length - 1 ? "1rem" : "0"
              }}
            >
              <div className="d-flex justify-content-between align-items-start">
                <div className="test-info">
                  <h6 className="mb-1">{test.title}</h6>
                  {test.subTitle && (
                    <div className="text-muted small">{test.subTitle}</div>
                  )}
                </div>
               
                <div className="test-price d-flex align-items-center">
                  <Banknote size={18} className="me-2 text-primary" />
                  <span className="fw-medium">₹{test.price}</span>
                </div>
              </div>
              <div className="mt-5">
                <ReportHandler id={id} axiosClient={axiosClient} testId={test?._id}/>
                </div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TestDetails;
