import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import AddAddress from "./AddAddressCart"; // Import the AddAddress component
import axiosClient from "../../axios-client";

const DateTime = ({ nextStep, tests }) => {
  const [addressType, setAddressType] = useState("home");
  const [homeLabel, setHomeLabel] = useState("Home");
  const [addressFields, setAddressFields] = useState({
    fullAddress: "",
    houseNo: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [date, setDate] = useState("Enter Date");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isEditing, setIsEditing] = useState(false); // State to track editing mode
  const [labs, setLabs] = useState([]);
  const [selectedLab, setSelectedLab] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const [error, setError] = useState(null); // State for error messages
  const [timeSlot, setTimeSlot] = useState("");
  const [totalAmount, setTotalAmount] = useState(0); // State for total amount
  const [extraCharge, setExtraCharge] = useState(100); // State for extra charges
  const [pincodeError, setPincodeError] = useState("");

  const allowedPincodes = ["411014", "412207", "411001", "411036", "411006"];

  // Define time slots
  const timeSlots = [
    "9:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 1:00 PM",
    "1:00 PM - 2:00 PM",
    "2:00 PM - 3:00 PM",
    "3:00 PM - 4:00 PM",
    "4:00 PM - 5:00 PM",
    "5:00 PM - 6:00 PM",
    "6:00 PM - 7:00 PM",
    "7:00 PM - 8:00 PM",
  ];

  const handleTimeSlotChange = (event) => {
    setTimeSlot(event.target.value);
  };

  useEffect(() => {
    fetchLabs();
    fetchTotalAmount();
  }, []);

  const fetchLabs = async () => {
    try {
      const response = await axiosClient.get("labs");
      setLabs(response.data.labs);
    } catch (error) {
      console.error("Error fetching labs:", error);
    }
  };

  // Fetch total amount from the API
  const fetchTotalAmount = async () => {
    try {
      const bookingId = localStorage.getItem("bookingId");
      if (!bookingId) {
        throw new Error("Booking ID not found in local storage.");
      }
      const response = await axiosClient.get(
        `/tests-booking/test/booking/${bookingId}`
      );
      // console.log(response.data);
      setTotalAmount(response.data.booking.totalAmount); // Set the fetched total amount
    } catch (error) {
      console.error("Error fetching total amount:", error);
    }
  };

  const handleRadioChange = (event) => {
    setAddressType(event.target.value);
    if (event.target.value === "lab") {
      setSelectedLab(null);
    }
  };
  const today = new Date().toISOString().split("T")[0];

  const handleLabChange = (event) => {
    const selectedLabId = event.target.value;
    const lab = labs.find((lab) => lab._id === selectedLabId);
    setSelectedLab(lab);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "pincode") {
      // Clear error when user starts typing
      setPincodeError("");

      // Only allow numbers and max 6 digits
      if (value.length <= 6 && /^\d*$/.test(value)) {
        setAddressFields((prev) => ({
          ...prev,
          [name]: value,
        }));

        // Check if pincode is allowed when user enters 6 digits
        if (value.length === 6 && !allowedPincodes.includes(value)) {
          setPincodeError(
            "Home Sample Collection is not available in your area"
          );
        }
      }
    } else {
      setAddressFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleOpenModal = () => {
    // console.log("Opening modal");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // console.log("Closing modal");
    setIsModalOpen(false);
    // Optionally reset address fields
    setAddressFields({
      fullAddress: "",
      houseNo: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
    });
  };
  const handleSaveAddress = () => {
    // Logic to save the address
    // console.log("Address saved:", addressFields);
    handleCloseModal();
  };

  // handleContinue Function
  const handleContinue = async () => {
    setIsLoading(true);
    setError(null); // Reset any previous errors
  
    try {
      const bookingId = localStorage.getItem("bookingId");
      if (!bookingId) {
        throw new Error("Booking ID not found in local storage.");
      }
  
      if (addressType === "home") {
        // Validate required fields for home address
        const requiredFields = [
          "fullAddress",
          "houseNo",
          "street",
          "city",
          "state",
          "pincode",
        ];
        const missingFields = requiredFields.filter(
          (field) => !addressFields[field].trim()
        );
        if (missingFields.length > 0) {
          throw new Error(
            `Missing required fields: ${missingFields.join(", ")}`
          );
        }
  
        // Check if pincode is allowed only for home address type
        if (!allowedPincodes.includes(addressFields.pincode)) {
          throw new Error("Home Sample Collection is not available in your area");
        }
      } else if (addressType === "lab" && !selectedLab) {
        // Validate lab selection
        throw new Error("Please select a lab.");
      }
  
      // Prepare the payload based on address type
      let payload = {
        testDate: date,
        timeSlot: timeSlot,
        isHome: addressType === "home",
        totalAmount: addressType === "home" ? totalAmount + extraCharge : totalAmount,
      };
  
      if (addressType === "home") {
        // Concatenate address fields into a single string
        const { houseNo, street, landmark, city, state, pincode } =
          addressFields;
  
        // Construct the homeAddress string
        let homeAddress = `${houseNo}, ${street}`;
        if (landmark) homeAddress += `, ${landmark}`;
        homeAddress += `, ${city}, ${state}, ${pincode}`;
  
        payload.homeAddress = homeAddress;
      } else if (addressType === "lab" && selectedLab) {
        payload.labAddress = selectedLab._id;
      }
  
      // Send the PUT request
      const response = await axiosClient.put(
        `/tests-booking/test/booking/${bookingId}`,
        payload
      );
  
      nextStep(); // Proceed to the next step
    } catch (err) {
      console.error("Error updating booking:", err);
      setError(
        err.response?.data?.message || err.message || "An error occurred."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
        {/* Selected Packages */}

        <div className="bg-white border-2 border-gray-300 p-10 rounded-2xl shadow-md relative">
          <div className="flex items-center mb-6 font-mont font-semibold text-xl">
            <label className="mr-8">
              <input
                type="radio"
                name="addressType"
                value="home"
                checked={addressType === "home"}
                onChange={handleRadioChange}
                className="mr-3 w-4 h-4 border border-red-500 rounded-full checked:bg-red-500 checked:border-red-500 focus:outline-red-600 cursor-pointer focus:outline-2 focus:outline-offset-2"
              />
              Home
            </label>
            <label>
              <input
                type="radio"
                name="addressType"
                value="lab"
                checked={addressType === "lab"}
                onChange={handleRadioChange}
                className="mr-3 w-4 h-4 cursor-pointer"
              />
              Lab
            </label>
          </div>

          {addressType === "lab" ? (
            <div className="">
              <h3 className="text-2xl font-bold mb-4 font-museo">
                Lab <span className="font1">Address</span>
              </h3>
              <select
                className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none font-mont"
                value={selectedLab ? selectedLab._id : ""}
                onChange={handleLabChange}
              >
                <option value="">Select a lab</option>
                {labs.map((lab) => (
                  <option key={lab._id} value={lab._id}>
                    {lab.name}
                  </option>
                ))}
              </select>
              {selectedLab && (
                <div className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 mt-5 font-mont font10 focus:ring-2 focus:ring-gray-200 focus:outline-none">
                  {`${selectedLab.house_no}, ${selectedLab.street}, ${selectedLab.landmark}, ${selectedLab.city}, ${selectedLab.state}, ${selectedLab.pincode}`}
                </div>
              )}

              <h3 className="text-2xl font-bold pt-16 mb-6 font-museo">
                Add <span className="font1">Date & Time</span>
              </h3>
              <input
                type="date"
                placeholder="Enter Date"
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={date}
                onChange={handleDateChange}
                min={today}
              />
              <select
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={timeSlot}
                onChange={handleTimeSlotChange}
              >
                <option value="">Select Time Slot</option>
                {timeSlots.map((slot, index) => (
                  <option key={index} value={slot}>
                    {slot}
                  </option>
                ))}
              </select>
              {/* <div className="flex items-center w-full justify-end font-museo font-semibold text-lg absolute bottom-10 right-10">
                <button className="w-1/4 bg-card1 text-white py-3 rounded-lg">
                  Submit
                </button>
              </div> */}
            </div>
          ) : (
            <div>
              <h3 className="text-2xl font-bold mb-4 font-museo">
                Add <span className="font1">Address</span>
              </h3>
              <div className="">
                <label className="block mb-2 font-mont font-semibold">
                  Save As
                </label>
                <select
                  className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none font-mont"
                  value={homeLabel}
                  onChange={(e) => setHomeLabel(e.target.value)}
                >
                  <option value="Home">Home</option>
                  <option value="Office">Office</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="">
                <label className="block mb-2 font-mont font-semibold">
                  Full Address
                </label>
                <input
                  type="text"
                  placeholder="Full Address"
                  className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 font-mont focus:outline-none"
                  name="fullAddress"
                  value={addressFields.fullAddress}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex gap-3">
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    House No.
                  </label>
                  <input
                    type="text"
                    placeholder="House No."
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="houseNo"
                    value={addressFields.houseNo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    Street
                  </label>
                  <input
                    type="text"
                    placeholder="Street"
                    className="w-full p-2 mb-3 border font-mont border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="street"
                    value={addressFields.street}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    Landmark
                  </label>
                  <input
                    type="text"
                    placeholder="Landmark"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="landmark"
                    value={addressFields.landmark}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    City
                  </label>
                  <input
                    type="text"
                    placeholder="City"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="city"
                    value={addressFields.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    State
                  </label>
                  <input
                    type="text"
                    placeholder="State"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="state"
                    value={addressFields.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    Pincode
                  </label>
                  <input
                    type="text"
                    placeholder="Pincode"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="pincode"
                    value={addressFields.pincode}
                    onChange={handleInputChange}
                    maxLength={6}
                  />
                </div>
              </div>
              {pincodeError && (
                <p className="text-red-500 text-sm mt-1 mb-3">{pincodeError}</p>
              )}

              <h3 className="text-2xl font-bold pt-10 mb-4 font-museo">
                Add <span className="font1">Date & Time</span>
              </h3>
              <input
                type="date"
                placeholder="Enter Date"
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={date}
                onChange={handleDateChange}
                min={today}
              />
              <select
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={timeSlot}
                onChange={handleTimeSlotChange}
              >
                <option value="">Select Time Slot</option>
                {timeSlots.map((slot, index) => (
                  <option key={index} value={slot}>
                    {slot}
                  </option>
                ))}
              </select>
              <div className="mt-20">
                <div className="flex items-center w-full justify-start font-euclid font-medium text-lg absolute  bottom-10 left-10 cursor-pointer">
                  <button
                    onClick={handleOpenModal}
                    className="w-2/5 font6 border-color1 py-3 rounded-lg cursor-pointer hover-color1 hover:text-white transition-all duration-300"
                  >
                    Add New Address
                  </button>
                </div>
                {/* <div className="flex items-center w-full justify-end font-museo font-semibold text-lg absolute bottom-10 right-10">
                  <button className="w-1/4 bg-card1 text-white py-3 rounded-lg">
                    Submit
                  </button>
                </div> */}
              </div>
            </div>
          )}
        </div>

        {/* Offers Section */}
        <div className="bg-white p-10 rounded-2xl shadow-md border-2 border-gray-300">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          {/* Display Offers */}
          <div className="space-y-8">
            {[1].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold pl-3">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="df" width={"27%"} />
                </div>
              </div>
            ))}
            <div className="py-3 px-5 bg-[#f8f8f8] border-b border-b-red-500">
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Total Amount</p>
                <p className="font7">₹{totalAmount}</p>
              </div>
              {addressType === "home" && (
                <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                  <p className="font7">Extra Charge </p>
                  <p className="font7">₹{extraCharge}</p>
                </div>
              )}
              <div className="font-museo text-lg flex items-center justify-between mt-1">
                <p className="font6">Amount to be paid </p>
                <p className="font6">₹{addressType === "home" ? totalAmount + extraCharge : totalAmount}</p>
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
            <button
              onClick={handleContinue}
              disabled={isLoading}
              className={`w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg hover-color1 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "Processing..." : "Continue To Next Step"}
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-4 text-red-500 text-center">{error}</div>
          )}
        </div>
      </div>
      {/* AddAddress Modal */}
      <AddAddress
        isEditing={isEditing}
        isAdding={isModalOpen}
        setIsEditing={setIsEditing}
        setIsAdding={setIsModalOpen}
        currentAddress={addressFields}
        handleInputChange={handleInputChange}
        handleSave={handleSaveAddress}
      />
    </div>
  );
};

export default DateTime;
