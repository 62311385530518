import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false); // State for locations submenu
  const [locations, setLocations] = useState([]); 
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };
  const token = localStorage.getItem("token");

  const handleLogout = async () => {
    try {
      await axiosClient.get("/users/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      navigate("/");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const toggleLocation = () => {
    setIsLocationOpen(!isLocationOpen); // Toggle the sub-menu visibility
  };

   // Fetch locations dynamically
   useEffect(() => {
    if (isOpen) {
      const fetchLocations = async () => {
        try {
          const response = await axiosClient.get("/labs");
          if (response.data.success) {
            // Extract unique cities from the labs data
            const cities = response.data.labs.map((lab) => ({
              id: lab._id,
              city: lab.city,
            }));
            setLocations(cities);
          }
        } catch (error) {
          console.error("Error fetching locations", error);
        }
      };

      fetchLocations();
    }
  }, [isOpen]); // Trigger fetching when sidebar is opened

  return (
    <>
      <div>
        <FiMenu className="w-6 h-6 cursor-pointer" onClick={toggleSidebar} />
      </div>
      {/* Overlay for sidebar */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 ${
          isOpen ? "block" : "hidden"
        }`}
      />

      {/* Sidebar on the left */}
      <div
        className={`fixed bg-blue-100 h-screen p-6 w-full max-w-xs md:max-w-sm top-0 left-0 shadow-lg text-black z-50 transform transition-transform duration-500 ease-in-out rounded-r-2xl flex flex-col justify-between ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className=" w-full">
          <div className="flex justify-between items-center mb-6">
            <img
              src={`${process.env.PUBLIC_URL}/images/final 2-03 (1).png`} // replace with your logo path
              alt="Logo"
              className="h-13 w-20"
            />
            <button onClick={closeSidebar} className="text-gray-500 text-2xl">
              <FiX className="w-4 h-4" />
            </button>
          </div>

          {/* Menu Items */}
          <nav className="space-y-4">
            <div>
              {/* Locations Button */}
              <button
                className="flex items-center bg-white text-blue-900  py-2 px-4 rounded-lg w-full hover:bg-blue-900 hover:text-white"
                onClick={toggleLocation}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/location.png`}
                  alt="Location"
                  width="18px"
                  height="18px"
                  className="mr-3"
                />
                <span>Locations</span>
              </button>

              {/* Sub-menu for Locations */}
              {isLocationOpen && (
                <div className="ml-8 mt-2 space-y-2">
                {locations.map((location) => (
                    <button
                      key={location.id}
                      className="block bg-white text-blue-900 py-2 px-4 rounded-lg w-full shadow hover:bg-blue-900 hover:text-white"
                      onClick={() => navigate(`/tests/lab/${location.id}`)}
                    >
                      {location.city}
                    </button>
                  ))}
                </div>
              )}
            </div>
            {/* <div>
              <Link to={"/"}>
                <button className="flex items-center bg-white text-blue-900 py-2 px-4 rounded-lg w-full shadow hover:bg-blue-900 hover:text-white">
                  <img
                    src="images/support.png"
                    alt="Support"
                    width="18px"
                    height="18px"
                    className="mr-3"
                  />
                  <span>Support</span>
                </button>
              </Link>
            </div> */}
            <div>
              <Link to={"/faq"}>
                <button className="flex items-center bg-white text-blue-900 py-2 px-4 rounded-lg w-full shadow hover:bg-blue-900 hover:text-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/qa.png`}
                    alt="FAQs"
                    width="18px"
                    height="18px"
                    className="mr-3"
                  />
                  <span>FAQs</span>
                </button>
              </Link>
            </div>

            <div>
              <Link to={"/about"}>
                <button className="flex items-center bg-white text-blue-900 py-2 px-4 rounded-lg w-full shadow hover:bg-blue-900 hover:text-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/about.png`}
                    alt="About Us"
                    width="18px"
                    height="18px"
                    className="mr-3"
                  />
                  <span>About Us</span>
                </button>
              </Link>
            </div>

            <div>
              <Link to={"/contact"}>
                <button className="flex items-center bg-white text-blue-900 py-2 px-4 rounded-lg w-full shadow hover:bg-blue-900 hover:text-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/contact.png`}
                    alt="Contact"
                    width="18px"
                    height="18px"
                    className="mr-3"
                  />
                  <span>Contact</span>
                </button>
              </Link>
            </div>

            <div>
            <Link to={"/blog"}>
            <button className="flex items-center bg-white text-blue-900 py-2 px-4 rounded-lg w-full shadow hover:bg-blue-900 hover:text-white">
              <img
                src={`${process.env.PUBLIC_URL}/images/blog.png`}
                alt="Blogs"
                width="18px"
                height="18px"
                className="mr-3"
              />
              <span>Blogs</span>
            </button>
            </Link>
            </div>

          </nav>
        </div>
        {/* Logout Button */}
        <div className=" logout-button">
          {token && (
            <button
              onClick={handleLogout}
              className="flex items-center bg-white text-red-500 py-2 px-4 rounded-lg w-full shadow hover:bg-red-700 hover:text-white"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logout.png`}
                alt="Logout"
                width="18px"
                height="18px"
                className="mr-3"
              />
              <span>Logout</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
