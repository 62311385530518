import React from "react";

function Instructions({ offers }) {
  return (
    <div className="max-w-screen-2xl mx-auto py-8 px-4 md:px-12 md:py-14 lg:px-20 lg:py-20">
      <div className="mb-8">
        <h3 className="font-museo  font4  text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Package Instructions
        </h3>
        <div className="flex items-start mt-4">
          <img
            src={`${process.env.PUBLIC_URL}/others/star-svgrepo-com.svg`}
            alt="star"
            className="w-4 h-4 mt-1"
          />
          <p className="font-mont font7 text-sm sm:text-base lg:text-lg ml-2 font-medium">
            {offers.instructions}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* First Card */}
        <div className="bg-card2 rounded-xl shadow-lg border-2 border-gray-300 overflow-hidden">
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1 pr-4  p-6">
              <h2 className="font-museo text-xl lg:text-2xl  font4 font-bold mb-3">
                {offers.title}
                <span className="font-semibold">{offers.subTitle}</span>
              </h2>
              <p className="font4 font-mont text-sm lg:text-base mb-4 line-clamp-3">
                It assesses various health aspects, including Vitamin Screening
                and free HsCRP to detect problems early.
              </p>
              <div className="font7 font-mont text-sm lg:text-base mb-4 space-x-4">
                <span className="mr-3">
                  <span className="font6">95</span> Parameters
                </span>
                <span>
                  <span className="font6">{offers.reportTime}</span> Reports
                </span>
              </div>
              <div className="inline-flex items-center bg-white rounded-lg p-3">
                <span className="text-xl lg:text-2xl font-bold font6">
                  ₹{offers.price}
                </span>
                <span className="ml-2 text-sm  font5">70% off</span>
              </div>
            </div>
            <div className="relative mt-6 sm:mt-0 sm:w-1/2 lg:w-2/5 md:py-10 lg:py-14 py-8">
              <img
                src={`${process.env.PUBLIC_URL}/young.png`}
                alt="Young Person"
                className="relative z-10 w-full h-auto object-cover lg:object-contain lg:h-[200px] p-6 lg:p-0"
              />
              <img
                src={`${process.env.PUBLIC_URL}/Vector 7354.png`}
                alt="Background Pattern"
                className="absolute bottom-0 right-0 md:w-full w-[90%] h-auto z-0 lg:h-[130px] lg:pb-10 pb-4"
              />
            </div>
          </div>
        </div>

        {/* Second Card */}
        <div className="bg-card3 rounded-xl shadow-lg border-2 border-gray-300 overflow-hidden">
          <div className="flex flex-col sm:flex-row ">
            <div className="flex-1 pr-4 p-6">
              <h2 className="font-museo text-xl lg:text-2xl font-bold font4 mb-3">
                {offers.title}
                <span className="font-semibold">{offers.subTitle}</span>
              </h2>
              <p className="font4 font-mont text-sm lg:text-base mb-4 line-clamp-3">
                It assesses various health aspects, including Vitamin Screening
                and free HsCRP to detect problems early.
              </p>
              <div className="font7 font-mont text-sm lg:text-base mb-4 space-x-4">
                <span className="mr-3">
                  <span className="font6">95</span> Parameters
                </span>
                <span>
                  <span className="font6">{offers.reportTime}Hrs</span> Reports
                </span>
              </div>
              <div className="inline-flex items-center bg-white rounded-lg p-3">
                <span className="text-xl lg:text-2xl font-bold font6">
                  ₹{offers.price}
                </span>
                <span className="ml-2 text-sm font5">70% off</span>
              </div>
            </div>
            <div className="relative mt-6 sm:mt-0 sm:w-1/2 lg:w-2/5 md:py-10 lg:py-14 py-8">
              <img
                src={`${process.env.PUBLIC_URL}/man.png`}
                alt="Young Person"
                className="relative z-10 w-full h-auto object-cover lg:object-contain lg:h-[200px]  p-6 lg:p-0"
              />
              <img
                src={`${process.env.PUBLIC_URL}/Vector blue.png`}
                alt="Background Pattern"
                className="absolute bottom-0 right-0  md:w-full w-[90%] h-auto z-0 lg:h-[130px] lg:pb-10 pb-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructions;
