import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";

const TrackProgress = () => {
  const [confirmedBookings, setConfirmedBookings] = useState([]);
  const [testDetailsMap, setTestDetailsMap] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchBookingData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) return;

      const { data } = await axiosClient.get(
        `/tests-booking/test/booking/user/${userId}`
      );
      const confirmedBookings = data.bookings.filter(
        (booking) => booking.bookingStatus !== "Pending"
      );

      if (confirmedBookings.length > 0) {
        setConfirmedBookings(confirmedBookings);

        const testDetailsPromises = confirmedBookings.map((booking) =>
          Promise.all(
            booking.test_id.map((testId) =>
              axiosClient
                .get(`/tests/test/${testId}`)
                .then((res) => res.data.test)
            )
          )
        );

        const allTestDetails = await Promise.all(testDetailsPromises);

        const testDetailsMap = confirmedBookings.reduce(
          (acc, booking, index) => {
            acc[booking._id] = allTestDetails[index];
            return acc;
          },
          {}
        );

        setTestDetailsMap(testDetailsMap);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching booking data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (confirmedBookings.length === 0) return <p>No booking data available.</p>;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "/");
  };

  const calculateProgressWidth = (lastActiveIndex, stagesLength) => {
    if (lastActiveIndex === -1) return "0%";
    if (lastActiveIndex === stagesLength - 1) return "100%";
    return `${(lastActiveIndex / (stagesLength - 1)) * 100}%`;
  };

  return (
    <div className="bg-white shadow-lg rounded-md p-6 mx-auto overflow-y-auto max-h-[340px]">
      {loading ? (
        <div>Loading...</div>
      ) : (
        confirmedBookings.map((booking) => {
          const testDetails = testDetailsMap[booking._id] || [];

          const progressStages = [
            {
              name: "Test Confirmed",
              date: booking.progress?.dates?.testConfirmedDate,
              isActive: booking.progress?.testConfirmed,
            },
            {
              name: "Sample Collected",
              date: booking.progress?.dates?.sampleCollectedDate,
              isActive: booking.progress?.sampleCollected,
            },
            {
              name: "Sample Processing",
              date: booking.progress?.dates?.sampleProcessingDate,
              isActive: booking.progress?.sampleProcessing,
            },
            {
              name: "Report Ready",
              date: booking.progress?.dates?.reportReadyDate,
              isActive: booking.progress?.reportReady,
            },
            {
              name: "Delivered",
              date: booking.progress?.dates?.deliveredDate,
              isActive: booking.progress?.delivered,
            },
          ];

          const lastActiveIndex = progressStages.reduce(
            (lastIndex, stage, index) => {
              return stage.isActive ? index : lastIndex;
            },
            -1
          );

          return (
            <div key={booking._id} className="mb-8">
              <div className="flex flex-col justify-between mb-4 pt-2">
                {testDetails.map((test, index) => (
                  <React.Fragment key={index}>
                    <div className="flex justify-between mt-7">
                      <h3 className="md:text-xl font-semibold font-museo">
                        {test.title}
                      </h3>
                      <p className="md:text-lg font-semibold font-museo">
                        ₹ {test.price}
                      </p>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className="hidden lg:block">
                <p className="text-gray-500 font-mont mb-6">Track Progress:</p>
                <div className="flex items-center justify-between relative px-2 sm:px-8">
                  <div className="absolute top-2 left-2 right-2 sm:left-4 sm:right-4 flex">
                    <div
                      className="absolute h-0.5 bg-gray-300"
                      style={{
                        left: "62px",
                        right: "52px",
                      }}
                    />

                    <div
                      className="absolute h-0.5 bg-blue-600 transition-all duration-300"
                      style={{
                        left: "62px",
                        width: calculateProgressWidth(
                          lastActiveIndex,
                          progressStages.length
                        ),
                      }}
                    />
                  </div>

                  {progressStages.map((stage, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center relative z-10"
                    >
                      <div className="relative">
                        <div
                          className={`w-3 h-3 sm:w-4 sm:h-4 rounded-full ${
                            stage.isActive ? "bg-blue-600" : "bg-gray-300"
                          }`}
                        />
                      </div>

                      <div className="mt-2 sm:mt-3 text-center">
                        <p className="text-xs sm:text-sm text-gray-700 font-mont whitespace-nowrap">
                          {stage.name}
                        </p>
                        {stage.isActive && stage?.date ? (
                          <p className="text-[10px] sm:text-xs text-gray-500 font-mont mt-1">
                            {formatDate(stage?.date)}
                          </p>
                        ) : (
                          <p className="text-[10px] sm:text-xs font-mont">
                            &nbsp;
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="h-[1px] w-full bg-gray-300 mt-2" />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default TrackProgress;
