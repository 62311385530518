import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";

function Footer() {
  const [info, setInfo] = useState();
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axiosClient.get("details");

        if (response.data.success) {
          setInfo(response.data.generalDetails);
          // console.log("Info",response.data.generalDetails)
        } else {
          throw new Error("Failed to fetch info.");
        }
      } catch (err) {
        console.error(err.message || "Failed to fetch info. Please try again.");
      }
    };

    fetchInfo();
  }, []);

  return (
    <div className="bg-secondary1">
      <footer className="mx-auto max-w-screen-2xl px-4 md:px-12 lg:px-20 md:py-14 py-8">
        <div className="mb-16 grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-5 lg:gap-10 md:gap-14 lg:pt-12 w-full">
          <div className="col-span-full lg:col-span-2">
            <div className="mb-4 lg:-mt-2">
              <a
                href="/"
                className="inline-flex items-center gap-2 text-xl font-bold text-white md:text-2xl"
                aria-label="logo"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/final 2-03.png`}
                  alt="logo"
                  className="h-[131px] w-[200px]"
                />
              </a>
            </div>

            <p className="mb-6 text-white text-sm leading-5 sm:pr-8 font-mont w-3/4">
              We are on a mission to provide quality healthcare services at
              affordable rates making diagnostics easily accessible and
              convenient to one and all.
            </p>

            <div className="flex md:gap-4 gap-2 ">
              <Link to="https://www.facebook.com/profile.php?id=100090442359211" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Facebook.png`}
                  alt="gf"
                  width={"38"}
                  height={"20"}
                  className="bg-[#363B4766] p-4 hover:bg-[#23BDEE]"
                />
              </Link>

              <Link to="https://www.instagram.com/palve_hospital/" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Instragarm.png`}
                  alt="gf"
                  width={"40"}
                  height={"30"}
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE]"
                />
              </Link>
              <Link to="https://www.linkedin.com/company/dr-palve-hospital-private-limited/?viewAsMember=true" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Linkedin.png`}
                  alt="gf"
                  width={"40"}
                  height={"30"}
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE]"
                />
              </Link>
              <Link to="https://api.whatsapp.com/send?phone=8149202506" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Twitter.png`}
                  alt="gf"
                  width={"40"}
                  height={"30"}
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE]"
                />
              </Link>
              <Link to="https://www.youtube.com/@drpalvehospitalpvtltd-jp1yi" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Youtube.png`}
                  alt="gf"
                  width={"45"}
                  height={"40"}
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE]"
                />
              </Link>
            </div>
          </div>

          <div className="md:col-span-1 col-span-full">
            <div className="mb-4 font-bold font-museo tracking-widest text-gray-100">
              Reach us
            </div>

            <nav className="flex flex-col gap-4">
              <div>
                <Link
                  to={`tel:${info?.phoneNumber}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/phn.png`}
                    alt="fg"
                    className="w-4 h-4"
                  />
                  <span className="text-white font-mont">{info?.phoneNumber}</span>
                </Link>
              </div>

              <div>
                <Link
                  to={`mailto:${info?.supportEmail}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mail.png`}
                    alt="fg"
                    className="w-4 h-3 flex-shrink-0"
                  />
                  <span className="text-white font-mont break-all">
                    {info?.supportEmail}
                  </span>
                </Link>
              </div>

              <div>
                <Link to={""} className="flex items-center gap-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/loc.png`}
                    alt="fg"
                    className="w-4 h-5"
                  />
                  <span className="text-white font-mont">
                    Pune, Maharashtra
                  </span>
                </Link>
              </div>
            </nav>
          </div>

          <div>
            <div className="mb-4 font-bold font-museo tracking-widest text-gray-100">
              Company
            </div>

            <nav className="flex flex-col gap-4">
              <div>
                <Link to={"/about"} className="font-mont text-white">
                  About
                </Link>
              </div>

              <div>
                <Link to={"/contact"} className="font-mont text-white">
                  Contact Us
                </Link>
              </div>

              {/* <div>
                <Link to={""} className="font-mont text-white">
                  Events
                </Link>
              </div> */}
            </nav>
          </div>

          <div>
            <div className="mb-4 font-bold font-museo tracking-widest text-gray-100">
              Legal
            </div>

            <nav className="flex flex-col gap-4">
              <div>
                <Link to={"/privacy"} className="font-mont text-white">
                  Privacy Policy
                </Link>
              </div>

              <div>
                <Link to={"/terms"} className="font-mont text-white">
                  Terms & Services
                </Link>
              </div>

              <div>
                <Link to={"/refund"} className="font-mont text-white">
                  Refund Policy
                </Link>
              </div>
            </nav>
          </div>

          <div></div>
        </div>
      </footer>
      <div className="font-museo bg-primary1 py-7 w-full text-center text-sm font leading-6 font-medium">
        © 2024 Dr Palve Lab Test. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;
