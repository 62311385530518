import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import { Trash2 } from "lucide-react";

const AddTestParameter = () => {
  const [testParameters, setTestParameters] = useState([
    { title: "", parameters: [""] }
  ]);
  const [tests, setTests] = useState([]);
  const [testId, setTestId] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosClient.get("tests/tests");
        setTests(response.data.test);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch tests. Please try again.");
      }
    };

    fetchTests();
  }, []);

  const handleTitleChange = (index, value) => {
    const newParameters = [...testParameters];
    newParameters[index].title = value;
    setTestParameters(newParameters);
  };

  const handleParameterChange = (titleIndex, paramIndex, value) => {
    const newParameters = [...testParameters];
    newParameters[titleIndex].parameters[paramIndex] = value;
    setTestParameters(newParameters);
  };

  const addTitle = () => {
    setTestParameters([
      ...testParameters,
      { title: "", parameters: [""] }
    ]);
  };

  const addParameter = (index) => {
    const newParameters = [...testParameters];
    newParameters[index].parameters.push("");
    setTestParameters(newParameters);
  };

  const removeParameter = (titleIndex, paramIndex) => {
    const newParameters = [...testParameters];
    newParameters[titleIndex].parameters.splice(paramIndex, 1);
    if (newParameters[titleIndex].parameters.length === 0) {
      newParameters[titleIndex].parameters = [""];
    }
    setTestParameters(newParameters);
  };

  const removeTitle = (index) => {
    const newParameters = testParameters.filter((_, idx) => idx !== index);
    if (newParameters.length === 0) {
      setTestParameters([{ title: "", parameters: [""] }]);
    } else {
      setTestParameters(newParameters);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!testId) {
      setError("Please select a test");
      return;
    }

    // Filter out empty parameters and validate titles
    const validParameters = testParameters.filter(param => 
      param.title.trim() !== "" && 
      param.parameters.some(p => p.trim() !== "")
    ).map(param => ({
      title: param.title,
      parameters: param.parameters.filter(p => p.trim() !== "")
    }));

    if (validParameters.length === 0) {
      setError("Please add at least one title with parameters");
      return;
    }

    try {
      await axiosClient.post("tests-parameters/test/parameter/add", {
        testId,
        testParameters: validParameters
      });

      setError("");
      alert("Test parameters added successfully!");
      navigate("/admin/all-test-parameter");
    } catch (error) {
      console.error("Error saving data: ", error);
      setError("Failed to save parameters. Please try again.");
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Add Test Parameters</h5>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="form-label font-semibold">Select Test</label>
                <select
                  className="form-control"
                  value={testId}
                  onChange={(e) => setTestId(e.target.value)}
                  required
                >
                  <option value="">Select Test</option>
                  {tests.map((test) => (
                    <option key={test._id} value={test._id}>
                      {test.title}
                    </option>
                  ))}
                </select>
              </div>

              {error && (
                <div className="alert alert-danger mb-4">{error}</div>
              )}

              <div className="flex justify-between p-2">
                <div className="add-button2">
                  <button type="button" onClick={addTitle}>
                    Add New Title
                  </button>
                </div>
                <div className="add-button2">
                  <button type="submit">
                    Save Parameters
                  </button>
                </div>
              </div>

              <div
                className="parameters-grid"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {testParameters.map((param, titleIndex) => (
                  <div
                    key={titleIndex}
                    style={{
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="font-semibold">Parameter Title</div>
                      <button
                        type="button"
                        onClick={() => removeTitle(titleIndex)}
                        className="text-red-600 hover:text-red-800"
                        title="Remove Title"
                        disabled={testParameters.length === 1}
                      >
                        <Trash2 size={18} />
                      </button>
                    </div>
                    <input
                      type="text"
                      value={param.title}
                      onChange={(e) => handleTitleChange(titleIndex, e.target.value)}
                      placeholder="Title"
                      className="form-control mb-2 ml-2 mt-2"
                      required
                    />

                    <div className="font-semibold">Parameters</div>
                    {param.parameters.map((parameter, paramIndex) => (
                      <div key={paramIndex} className="flex items-center gap-2">
                        <input
                          type="text"
                          value={parameter}
                          onChange={(e) =>
                            handleParameterChange(
                              titleIndex,
                              paramIndex,
                              e.target.value
                            )
                          }
                          placeholder="Parameter"
                          className="form-control mb-2 ml-2 flex-grow"
                          style={{ marginTop: "5px" }}
                          required
                        />
                        <button
                          type="button"
                          onClick={() => removeParameter(titleIndex, paramIndex)}
                          className="text-red-600 hover:text-red-800 mb-2"
                          title="Remove Parameter"
                          disabled={param.parameters.length === 1}
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    ))}

                    <div className="add-button2 mt-2">
                      <button 
                        type="button" 
                        onClick={() => addParameter(titleIndex)}
                      >
                        Add Parameter
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddTestParameter;