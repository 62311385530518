

import React, { useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Banner from "./BlogBanner";
import "../../../src/index.css";
import { FaHeart } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import iziToast from "izitoast";
import axiosClient from "../../axios-client"
import { Modal } from "react-bootstrap";

const Blog = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [likedCards, setLikedCards] = useState(Array(3).fill(false));
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Email validation regex
    const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,7}$/;

    if (!email || !emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setError(""); // Clear any previous error

    try {
      const response = await axiosClient.post("/news", { email });

      if (response.status === 201) {
        // Show success modal
        setModalMessage("Thank you for subscribing to our newsletter!");
        setShowModal(true);
        setEmail(""); // Reset email input
      } else {
        // Handle other response statuses if necessary
        setModalMessage("Subscription failed. Please try again.");
        setShowModal(true);
      }
    } catch (err) {
      // Handle error
      setModalMessage("An error occurred. Please try again later.");
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleShare = (title, text) => {
    const shareUrl = window.location.href; 

    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: text,
          url: shareUrl,
        })
        .then(() => {
          iziToast.success({
            message: "Blog shared successfully!",
            position: "topCenter"
          });
        })
        .catch((error) => {
          iziToast.error({
            message: "Error sharing the blog. Please try again.",
            position: "topCenter"
          });
          console.error("Error sharing", error);
        });
    } else {
      iziToast.error({
        message: "Your browser does not support sharing. Please copy the URL manually: " + shareUrl,
        position: "topCenter"
      });
    }
  };

  const toggleLike = (index) => {
    const updatedLikedCards = [...likedCards];
    updatedLikedCards[index] = !updatedLikedCards[index]; 
    setLikedCards(updatedLikedCards);
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />

      <div className="min-h-screen p-4 md:p-8 w-full max-w-[1315px] mx-auto relative mt-14">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
          {/* Left Side Content */}
          <div className="lg:col-span-2">
            {[ // Blog Posts Array
              {
                image: "./images/viktor-forgacs-Yn9LiXt53jQ-unsplash 1.png",
                date: "02 June 2024",
                title: "Mauris et neque hendrerit bortis turpis giat nisl",
                text: "Quality non lorem ac erat suscipit bibendum. Nulla facilisi...",
              },
              {
                image: "./images/viktor-forgacs-Yn9LiXt53jQ-unsplash 1 (1).png",
                date: "02 June 2024",
                title: "Mauris et neque hendrerit bortis turpis giat nisl",
                text: "Quality non lorem ac erat suscipit bibendum. Nulla facilisi...",
              },
              {
                image: "./images/machine.png",
                date: "02 June 2024",
                title: "Mauris et neque hendrerit bortis turpis giat nisl",
                text: "Quality non lorem ac erat suscipit bibendum. Nulla facilisi...",
              },
            ].map((card, index) => (
              <div key={index} className="border border-[1px solid #EEEEEE] rounded-xl mb-10 w-full max-w-[750px] h-auto">
                <div className="overflow-hidden rounded-t-xl">
                  <img src={card.image} alt={card.title} className="w-full" />
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                      <img src="./images/Group.png" alt="Description" className="w-5 mr-3" />
                      <p className="text-[#4C555D]">{card.date}</p>
                    </div>
                    <div className="flex space-x-5">
                    <FaHeart 
  className={`w-6 transition duration-200 ease-in-out ${likedCards[index] ? 'text-red-500' : '#4C555D'}`}
  onClick={() => toggleLike(index)} // Toggle the like state on click
/>

                      <FaShareAlt 
                        className="w-6 text-[#4C555D] transition duration-200 ease-in-out hover:text-blue-500 cursor-pointer"
                        onClick={() => handleShare(card.title, card.text)}
                      />
                    </div>
                  </div>
                  <hr className="my-4 border-gray-300" />
                  <h2 className="font-bold text-lg text-[#394858] mb-2 font-museo">
                    {card.title}
                  </h2>
                  <p className="text-[#999999] mb-4 font-mont">{card.text}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Right Side Content */}
          <div className="space-y-4">
            <div className="relative mb-14">
              <h3 className="text-xl mb-6 font-mont font-semibold">Top News</h3>
              <div className="space-y-4">
                {/* Top News Items */}
                <div className="font-museo bg-white flex items-center justify-center p-2 text-[#41495B] w-full max-w-[380px] h-[69px] rounded-lg border border-[#ABBACB] shadow transition-all duration-200 transform hover:scale-105 hover:shadow-lg hover:border-transparent cursor-pointer mb-4">
                  Interdum et malesuada fames ac ante ipsum
                </div>
                <div className="font-museo bg-white flex items-center justify-center p-2 text-[#41495B] w-full max-w-[380px] h-[69px] rounded-lg border border-[#ABBACB] shadow transition-all duration-200 transform hover:scale-105 hover:shadow-lg hover:border-transparent cursor-pointer mb-4">
                  Duis aliquet lectus nec faucibus laoreet feugiat
                </div>
                <div className="font-museo bg-white flex items-center justify-center p-2 text-[#41495B] w-full max-w-[380px] h-[69px] rounded-lg border border-[#ABBACB] shadow transition-all duration-200 transform hover:scale-105 hover:shadow-lg hover:border-transparent cursor-pointer mb-6">
                  Sed condi mentum nim id luctus tempu que nibh
                </div>
              </div>
            </div>

            {/* Newsletter Container */}
            <div className="border border-[#D9D9D9] p-6 bg-[#F9F9F9] rounded-[10px] relative mt-10 w-full max-w-[400px] h-[240px]">
              <h3 className="text-xl font-semibold mb-2 font-mont">Newsletter</h3>
              <hr className="border border-[#D5D8DC] mb-2" />
              <div className="mt-7">
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-7 rounded font-mont"
                    required
                  />
                  {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
                  <button className="border border-transparent bg-[#1E4FA6] text-white py-2 px-4 w-full rounded font-museo transition-all duration-200 hover:bg-white hover:text-[#1E4FA6] hover:border hover:border-[#1E4FA6]">
                    Subscribe Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Success/Error Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <div className="text-center text-xl font-bold py-4 font-museo">
            <h4>{modalMessage}</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="inline-block font-museo btn-color px-4 sm:px-6 py-2 text-center text-sm font-semibold text-white rounded-lg outline-none transition duration-100 hover:bg-red-600 active:bg-red-700"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <NewsLetter />
      <Footer />
    </>
  );
};

export default Blog;
