// BenefitsSection.js
const benefits = [
  {
    id: 1,
    imgSrc: "images/photo2.png",
    title: "Reports Consultation",
  },
  {
    id: 2,
    imgSrc: "images/photo1.png",
    title: "On-time Sample Collection",
  },
  {
    id: 3,
    imgSrc: "images/photo3.png",
    title: "Affordable Pricing",
  },
  {
    id: 4,
    imgSrc: "images/photo4.png",
    title: "Certified Labs",
  },
];

const BenefitsSection = () => {
  return (
    <div className=" max-w-screen-2xl mx-auto md:py-14 lg:py-10 py-8 lg:px-20 md:px-12 px-4">
      <h3 className="font-museo font4 text-2xl font-semibold pb-10">
        Benefits of Choosing Us:
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {benefits.map((benefit) => (
          <div
            key={benefit.id}
            className="bg-card1 text-white rounded-lg p-6 flex flex-col items-center justify-center shadow-lg"
          >
            <div className="bg-white rounded-full p-4 mb-4">
              <img
                src={`${process.env.PUBLIC_URL}/${benefit.imgSrc}`}
                alt={benefit.title}
                className="w-8 h-8"
              />
            </div>
            <h3 className="text-lg font-medium text-center font-mont">
              {benefit.title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
