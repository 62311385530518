import React from "react";

function KeyFeatures() {
  const features = [
    {
      img: "images/heart-rate 1 (1).png", 
      text: "From tamper-proof sample collection to accurate reports, we deploy advanced technology and quality control processes.",
    },
    {
      img: "images/heart-rate 1 (2).png",
      text: "Our focus is to make diagnostics accessible to everyone from cities to remotest parts of the country.",
    },
    {
      img: "images/heart-rate 1.png",
      text: "Right from booking the test to making reports available in no time, we have created a seamless customer journey.",
    },
    {
      img: "images/heart-rate 1 (1).png",
      text: "From tamper-proof sample collection to accurate reports, we deploy advanced technology and quality control processes.",
    },
  ];

  return (
    <div
      className="bg-cover bg-center"
      style={{
        backgroundImage: 'url("images/features.png")',
        width: "100%",
        height: "593px",
      }}
    >
      {/* Header Text */}
      <div className="max-w-screen-2xl px-4 sm:px-6 lg:px-20 py-20">
        <div className="text-center mb-20">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white font-museo">
            Our <span className="font6">Key Features</span>
          </h1>
        </div>

        {/* Feature Cards Container */}
        <div className="flex flex-col md:flex-row overflow-x-auto md:overflow-y-auto gap-5 max-h-[400px] md:max-h-[593px]">
          {features.map((feature, index) => (
            <div
              key={index}
              className="text-center p-5 rounded-lg border border-[#FFFFFF] flex flex-col justify-between gap-4 min-w-[240px] md:w-1/4 lg:w-1/4"
            >
              {/* Image with white background */}
              <div className="flex justify-center mb-4">
                <div className="p-4 rounded-full shadow-md bg-white h-[90px] w-[90px] flex items-center justify-center">
                  <img
                    src={feature.img}
                    alt={`Feature ${index + 1}`}
                    className="w-16 h-16"
                  />
                </div>
              </div>
              {/* Text */}
              <p className="text-sm md:text-base text-[#FFFFFF] flex-grow flex items-center justify-center font-mont">
                {feature.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default KeyFeatures;
