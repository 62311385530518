import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import { useNavigate } from "react-router-dom"; // Adjust the path based on your project structure
import axiosClient from "../../axios-client";

const MedicalSummary = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState({
    height: "",
    weight: "",
    hadCovid: "No",
    diabetes: "No",
    thyroid: "No",
    bloodPressure: "Normal",
    heartDisease: "No",
    pain: "No",
    otherRemarks: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId"); // Ensure userId is stored in localStorage

      if (!token || !userId) {
        navigate("/register"); // Redirect if no token or userId
        return;
      }

      try {
        const response = await axiosClient.get(`/users/details/${userId}`);

        if (response.data.success) {
          const user = response.data.user;
          setFormData({
            height: user.height || "",
            weight: user.weight || "",
            hadCovid: user.hasCovid ? "Yes" : "No",
            diabetes: user.diabetes ? "Yes" : "No",
            thyroid: user.thyroid ? "Yes" : "No",
            bloodPressure: user.bloodPressure || "Normal",
            heartDisease: user.heartDisease ? "Yes" : "No",
            pain: user.pain ? "Yes" : "No",
            otherRemarks: user.otherRemark || "",
          });
        } else {
          setError("Failed to fetch user details.");
        }
      } catch (err) {
        console.error(err);
        setError("An error occurred while fetching user details.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  // Toggle edit mode
  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      navigate("/register");
      return;
    }

    // Prepare data for API
    const updatedData = {
      height: formData.height,
      weight: formData.weight,
      hasCovid: formData.hadCovid === "Yes",
      diabetes: formData.diabetes === "Yes",
      thyroid: formData.thyroid === "Yes",
      bloodPressure: formData.bloodPressure,
      heartDisease: formData.heartDisease === "Yes",
      pain: formData.pain === "Yes",
      otherRemark: formData.otherRemarks,
    };

    try {
      const response = await axiosClient.put(
        `/users/details/${userId}`,
        updatedData
      );

      if (response.data.success) {
        setIsEditable(false);
        // console.log("Updated Data: ", updatedData);
        // Optionally, you can refetch the data or show a success message
      } else {
        setError("Failed to update user details.");
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred while updating user details.");
    }
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <Navbar2 />
        <div className="max-w-screen-2xl mx-auto py-20 px-4">
          <p>Loading...</p>
        </div>
        <NewsLetter />
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <Navbar2 />
        <div className="max-w-screen-2xl mx-auto py-20 px-4">
          <p className="text-red-500">{error}</p>
        </div>
        <NewsLetter />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4 ">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="w-3/4 p-8">
            <div className="flex flex-col justify-end sm:flex-row sm:justify-between items-start mb-4">
              <h1 className="text-lg sm:text-xl md:text-2xl font-semibold font-museo font4 mb-2 sm:mb-0">
                Medical History
              </h1>
              <button
                onClick={handleEdit}
                className="text-blue-500 border border-blue-500 px-2 py-1 rounded sm:self-start"
              >
                {isEditable ? "Cancel" : "Edit"}
              </button>
            </div>

            {/* Conditionally Render Form */}
            {isEditable ? (
              <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                {/* Height */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Height
                  </label>
                  <input
                    type="text"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  />
                </div>

                {/* Weight */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Weight
                  </label>
                  <input
                    type="text"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  />
                </div>

                {/* Had Covid */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Had Covid
                  </label>
                  <select
                    name="hadCovid"
                    value={formData.hadCovid}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                {/* Diabetes */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Diabetes
                  </label>
                  <select
                    name="diabetes"
                    value={formData.diabetes}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                {/* Thyroid */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Thyroid
                  </label>
                  <select
                    name="thyroid"
                    value={formData.thyroid}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                {/* Blood Pressure */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Blood Pressure
                  </label>
                  <select
                    name="bloodPressure"
                    value={formData.bloodPressure}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  >
                    <option value="High">High</option>
                    <option value="Normal">Normal</option>
                    <option value="Low">Low</option>
                  </select>
                </div>

                {/* Heart Disease */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Heart Disease
                  </label>
                  <select
                    name="heartDisease"
                    value={formData.heartDisease}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                {/* Pain */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Pain
                  </label>
                  <select
                    name="pain"
                    value={formData.pain}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                {/* Other Remarks */}
                <div className="col-span-2">
                  <label className="block font-medium font7 font-mont capitalize">
                    Other Remarks
                  </label>
                  <textarea
                    name="otherRemarks"
                    value={formData.otherRemarks}
                    onChange={handleChange}
                    className="w-full p-2 border border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div className="col-span-2 w-1/5 mt-5">
                  <button
                    type="submit"
                    className="w-full bg-card1 text-white font-museo py-2 font-semibold rounded"
                  >
                    Update
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                {/* Height */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Height
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.height || "N/A"}
                  </p>
                </div>

                {/* Weight */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Weight
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.weight || "N/A"}
                  </p>
                </div>

                {/* Had Covid */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Had Covid
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.hadCovid}
                  </p>
                </div>

                {/* Diabetes */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Diabetes
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.diabetes}
                  </p>
                </div>

                {/* Thyroid */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Thyroid
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.thyroid}
                  </p>
                </div>

                {/* Blood Pressure */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Blood Pressure
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.bloodPressure}
                  </p>
                </div>

                {/* Heart Disease */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Heart Disease
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.heartDisease}
                  </p>
                </div>

                {/* Pain */}
                <div>
                  <label className="block font-medium font7 font-mont capitalize">
                    Pain
                  </label>
                  <p className="font-mont font6 font-bold">{formData.pain}</p>
                </div>

                {/* Other Remarks */}
                <div className="col-span-2">
                  <label className="block font-medium font7 font-mont capitalize">
                    Other Remarks
                  </label>
                  <p className="font-mont font6 font-bold">
                    {formData.otherRemarks || "N/A"}
                  </p>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default MedicalSummary;
