import React from "react";

const StatsSection = () => {
  return (
    <div className="max-w-screen-2xl mx-auto py-10 px-4 sm:px-6 md:px-8 lg:px-20 md:py-20">
      <div className="relative bg-tertiary1 h-auto md:h-[138px] text-white rounded-xl flex flex-col md:flex-row justify-around items-center overflow-hidden max-w-screen-2xl mx-auto">
        {/* Stats */}
        <div className="flex flex-col md:flex-row justify-between w-full max-w-screen-lg items-center z-20">
          {/* Test Performed */}
          <div className="flex flex-col items-center justify-center p-4 font-museo">
            <h2 className="text-3xl md:text-4xl  font-bold">12000+</h2>
            <p className="text-base md:text-lg lg:text-xl mt-2">
              Test Performed
            </p>
          </div>

          {/* Divider */}
          <div className="hidden md:block h-20 w-px bg-white opacity-50"></div>

          {/* Years of Experience */}
          <div className="flex flex-col items-center justify-center p-4 font-museo">
            <h2 className="text-3xl md:text-4xl  font-bold">12+</h2>
            <p className="text-base md:text-lg lg:text-xl mt-2">
              Years Of Experience
            </p>
          </div>

          {/* Divider */}
          <div className="hidden md:block h-20 w-px bg-white opacity-50"></div>

          {/* Locations */}
          <div className="flex flex-col items-center justify-center p-4 font-museo">
            <h2 className="text-3xl md:text-4xl font-bold">5+</h2>
            <p className="text-base md:text-lg lg:text-xl mt-2">Locations</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
