import React from "react";
import { Dialog } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";

function AddAddress({
  isEditing,
  isAdding,
  setIsEditing,
  setIsAdding,
  currentAddress,
  handleInputChange,
  handleSave,
}) {
  return (
    <Dialog
      open={isEditing || isAdding}
      onClose={() => {
        setIsEditing(false);
        setIsAdding(false);
      }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 transition-opacity duration-300"
    >
      <Dialog.Panel className="bg-white rounded-lg shadow-md w-full max-w-lg">
        <Dialog.Title className="flex justify-between items-center text-xl font-bold mb-4 font-museo border-b-2 px-6 py-4">
          <span>{isEditing ? "Edit Address" : "Add New Address"}</span>
          <button
            onClick={() => {
              setIsEditing(false);
              setIsAdding(false);
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            <RxCross2 className="h-6 w-6" aria-hidden="true" />
          </button>
        </Dialog.Title>

        <div className="grid grid-cols-2 gap-4 mx-6">
          <div className="col-span-2">
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="name"
            >
              Save As
            </label>
            <input
              name="name"
              value={currentAddress?.name || ""}
              onChange={handleInputChange}
              placeholder="Save As"
              className="w-full p-2 border rounded-lg font-mont"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="houseNo"
            >
              House No.
            </label>
            <input
              name="houseNo"
              value={currentAddress?.houseNo || ""}
              onChange={handleInputChange}
              placeholder="House No."
              className="w-full p-2 border rounded-lg font-mont"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="street"
            >
              Street
            </label>
            <input
              name="street"
              value={currentAddress?.street || ""}
              onChange={handleInputChange}
              placeholder="Street"
              className="w-full p-2 border rounded-lg font-mont"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="landmark"
            >
              Landmark
            </label>
            <input
              name="landmark"
              value={currentAddress?.landmark || ""}
              onChange={handleInputChange}
              placeholder="Landmark"
              className="w-full p-2 border rounded-lg font-mont"
            />
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="city"
            >
              City
            </label>
            <input
              name="city"
              value={currentAddress?.city || ""}
              onChange={handleInputChange}
              placeholder="City"
              className="w-full p-2 border rounded-lg font-mont"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="state"
            >
              State
            </label>
            <input
              name="state"
              value={currentAddress?.state || ""}
              onChange={handleInputChange}
              placeholder="State"
              className="w-full p-2 border rounded-lg font-mont"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="pincode"
            >
              Pincode
            </label>
            <input
              name="pincode"
              type="number"
              value={currentAddress?.pincode || ""}
              onChange={handleInputChange}
              placeholder="Pincode"
              className="w-full p-2 border rounded-lg font-mont"
              required
            />
          </div>
        </div>

        {/* Button Container */}
        <div className="flex justify-end mt-5 border-t-2 pt-4 mb-4 pr-4 font-mont">
          <button
            onClick={handleSave}
            className="bg-card1 text-white py-2 px-5 rounded-lg font-museo"
          >
            {isEditing ? "Update Address" : "Add Address"}
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}

export default AddAddress;
