import React, { useEffect } from "react";
import { useAuth } from "../../AuthContext";

const Modal1 = ({ isOpen, onClose, report }) => {
  const { filepath } = useAuth();
  if (!isOpen) return null; 
  // console.log("report", report)

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">
           Report Preview
        </h2>
        {/* <p className="mb-4">Report completed on: </p>
        <p className="text-lg font-semibold">Price: ₹ </p> */}
        <div className="mt-4">
          <iframe
            src={`${filepath}reports/${report}`}
            width="100%"
            height="450px"
            title="preview"
          />
        </div>

        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal1;
