import React, { useEffect, useState } from "react";
import Band from "./Band";
import Instructions from "./Instructions";
import IncludedTests from "./IncludedTests";
import BenefitsSection from "./BenefitSection";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import axiosClient from "../../axios-client";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";

function BodyCheckup() {
  const [offers, setOffers] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredParameters, setFilteredParameters] = useState(null);
  const [parametersLength, setParametersLength] = useState(0);
  const { id } = useParams();
  const { addToCart, isInCart, filepath } = useAuth();

  const handleBuyNow = (testId) => {
    addToCart(testId);
  };

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axiosClient(`/tests/test/${id}`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setOffers(data.test || []);
      } catch (error) {
        setError("Failed to fetch offers");
        console.error("There was a problem with the fetch operation:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  useEffect(() => {
    const fetchParameters = async () => {
      try {
        const response = await axiosClient.get(`/tests-parameters/test/parameters/test/${id}`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const parameters = await response.data;
        setParameters(parameters.testParameters || []); 
        setParametersLength(parameters.totalParametersCount || 0);
        setFilteredParameters(parameters.testParameters || []);
      } catch (error) {
        setError("Failed to fetch parameters");
        console.error("There was a problem with the fetch parameters:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchParameters();
  }, [id]);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  // if (offers.length === 0)
  //   return <div className="text-center">No offers available</div>;
  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        {/* Container for the whole card */}
        <div className="flex flex-col md:flex-row bg-white">
          {/* Left Side - Textual Information */}
          <div className="lg:w-3/4 md:w-3/5  ">
            <h2 className="lg:text-4xl md:text-3xl text-2xl  font-bold font4 font-museo">
              {offers.title}
              <span className="font6"> {offers.subTitle}</span>
            </h2>
            <p className="lg:text-xl text-lg font7 font-mont lg:py-6 py-3">
              <span className="font-bold font6">
                {parametersLength}
              </span>{" "}
              Parameters
            </p>

            {/* Fasting and Reports Information */}
            <div className="flex mt-2 lg:mt-4">
              <div className="bg-card1 text-white text-center rounded-xl lg:py-4 lg:px-24 py-2 px-10 mr-4 shadow-xl text-sm lg:text-base hover-color1">
                <p className="font-bold  font-museo">{offers.fastingTime}</p>
                <p className="font-mont">Fasting</p>
              </div>
              <div className="bg-card1 text-white text-center text-sm lg:text-base rounded-xl lg:py-4 lg:px-24 py-2 md:px-10 px-9 shadow-xl hover-color1">
                <p className="font-bold font-museo">{offers.reportTime}</p>
                <p className="font-mont">Reports</p>
              </div>
            </div>

            {/* Usage information */}
            <p className="mt-6 lg:text-2xl text-xl font-semibold font4 font-museo lg:pt-8">
              What is it Used For?
            </p>
            <p className="lg:text-lg text-base font-mont font7 pt-3 lg:w-3/4">
              {offers.usedFor}
            </p>
          </div>

          {/* Right Side - Image and Add to Cart */}
          <div className="md:w-1/3 mt-6 md:mt-0 md:ml-12 lg:ml-20">
            <div
              className=" lg:rounded-2xl rounded-xl p-4 bg-secondary1 lg:h-[415px] lg:w-[100%]"
              style={{}}
            >
              <img
                src={filepath + offers.testImage}
                alt="Checkup"
                className="w-full lg:h-[210px] md:h-[160px] object-cover rounded-lg lg:mt-2 mt-1"
              />

              {/* Price and Add to Cart */}
              <div className="mt-6 text-center">
                <p className="font5 font-museo lg:text-lg text-base font-medium bg-white lg:px-4 lg:py-3 py-2 rounded-lg shadow-md ">
                  {" "}
                  <span className="md:font-extrabold font-bold font6 mr-3 font-museo">
                    ₹{offers.price}
                  </span>
                  70% off
                </p>
                <button
                  className="bg-card1 hover-color1 font-museo font-bold text-white lg:text-lg text-base lg:py-4 px-4 py-2 mt-4 rounded-lg w-full shadow-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBuyNow(offers._id);
                  }}
                >
                  {isInCart(offers._id) ? (
                    <Link to="/steps" className="w-full block">
                      View Cart
                    </Link>
                  ) : (
                    "Add To Cart"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Band />
      <Instructions offers={offers} />
      <BenefitsSection />
      <IncludedTests parameters={filteredParameters} />
      <div className="max-w-screen-2xl mx-auto md:pb-14 lg:pb-20 pb-8">
        <Band />
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default BodyCheckup;
