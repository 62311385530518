import React from "react";

function HowWeStarted() {
  return (
    <div className="max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-20 pt-20 pb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <img
          src="images/lab.png"
          alt="Lab"
          className="w-full h-auto"
        />
        <div className="font-mont mt-5">
          <h3 className="font-museo text-2xl md:text-3xl font-bold mb-5 text-black">
            How we <span className="font6">Started</span>
          </h3>
          <p className="mb-2 text-base md:text-lg">
            Our journey began in 2018 with a clear mission: to address the
            critical challenge of making quality healthcare accessible to
            everyone across our diverse country. We were driven by a profound
            question—how can we bridge the gap between healthcare needs and the
            resources available to meet them?
          </p>
          <p className="mb-2 text-base md:text-lg">
            Over the years, we've committed ourselves to breaking down barriers,
            leveraging technology, and fostering partnerships that empower
            individuals with the healthcare they deserve. Every step we've taken
            has been guided by our unwavering belief that quality healthcare is
            not just a privilege, but a fundamental right for all.
          </p>
          <p className="mb-2 text-base md:text-lg">
            Today, as we continue to grow and evolve, our mission remains the
            same: to ensure that every person, regardless of where they live,
            can access the care they need to lead a healthy, fulfilling life.
          </p>
        </div>
        
        {/* Image for Vision & Mission Section, hidden on medium screens and above */}
        <img
          src="images/lab.png"
          alt="Lab"
          className="w-full h-auto mt-3 md:hidden"
        />
        
        <div className="font-mont mt-8">
          <h3 className="font-museo text-2xl md:text-3xl font-bold mb-5 text-black">
            Our <span className="font6">Vision & Mission</span>
          </h3>
          <p className="mb-2 text-base md:text-lg">
            Our vision is to build a high-quality and affordable diagnostics
            platform that reaches every corner of Bharat, empowering individuals
            with the knowledge and tools to take charge of their health. We
            believe that access to reliable and cost-effective diagnostics is
            the cornerstone of preventive healthcare and early intervention.
            Through this platform, we are committed to transforming the
            healthcare landscape, driving better health outcomes, and fostering
            a healthier, more resilient nation.
          </p>
          <p className="mb-2 text-base md:text-lg">
            Our mission is to revolutionize healthcare in Bharat by creating a
            diagnostics platform that is both high-quality and affordable,
            ensuring that every individual has access to essential health
            services. We are dedicated to bridging the gap between urban and
            rural healthcare by bringing advanced diagnostic technologies to the
            underserved and remote areas of our country.
          </p>
        </div>

        {/* Image for Vision & Mission Section, visible only on medium screens and above */}
        <img
          src="images/lab.png"
          alt="Lab"
          className="w-full h-auto mt-3 hidden md:block"
        />
      </div>
    </div>
  );
}

export default HowWeStarted;
