import React, { useEffect, useState } from 'react';
import { Card, Table, Alert } from 'react-bootstrap';
import axiosClient from '../../axios-client';

const AddressCard = ({ bookings }) => {
  const [labAddress, setLabAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLabAddress = async () => {
      if (bookings?.labAddress) {
        try {
          const response = await axiosClient.get(`labs/${bookings.labAddress}`);
          if (response.data.success) {
            setLabAddress(response.data.lab);
          } else {
            setError('Failed to fetch lab address.');
          }
        } catch (err) {
          setError('Error fetching lab address.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchLabAddress();
  }, [bookings]);

  return (
    <Card className="mb-4 shadow-sm">
      <Card.Header className="text-lg font-bold">Test Address</Card.Header>
      <Card.Body>
        {bookings?.isHome ? (
          <Table>
            <tbody>
              <tr>
                <td>{bookings.homeAddress}</td>
              </tr>
            </tbody>
          </Table>
        ) : loading ? (
          <p>Loading...</p> // Show loading state
        ) : labAddress ? (
          <Table>
            <tbody>
              <tr>
                <td>
                {labAddress.name}, {labAddress.house_no}, {labAddress.street}, {labAddress.landmark}, {labAddress.city}, {labAddress.state}, {labAddress.pincode}
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Alert variant="info" className="text-center">
            No lab address available.
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default AddressCard;
