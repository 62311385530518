import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import IncludedTests2 from './landingPage/IncludedTest2';
import axiosClient from '../axios-client';

const SideSheet = ({ selectedCheckup, isSheetOpen, closeSheet }) => {
    const { addToCart, isInCart } = useAuth();
    const [filteredParameters, setFilteredParameters] = useState(null);
    const [parametersLength, setParametersLength] = useState(0);

    const handleBuyNow = (testId) => {
      addToCart(testId);
    };

    useEffect(() => {
      const fetchParameters = async () => {
        try {
          const response = await axiosClient.get(`/tests-parameters/test/parameters/test/${selectedCheckup?._id}`);
          if (response.status !== 200) {
            throw new Error("Network response was not ok");
          }
          const parameters = await response.data;
          // setParameters(parameters.testParameters || []); 
          setParametersLength(parameters.totalParametersCount || 0);
          setFilteredParameters(parameters.testParameters || []);
        } catch (error) {
          // setError("Failed to fetch parameters");
          console.error("There was a problem with the fetch parameters:", error);
        } 
      };
    
      fetchParameters();
    }, [selectedCheckup?._id]);

    const benefits = [
        {
          id: 1,
          imgSrc: "images/photo2.png",
          title: "Reports Consultation",
        },
        {
          id: 2,
          imgSrc: "images/photo1.png",
          title: "On-time Sample Collection",
        },
        {
          id: 3,
          imgSrc: "images/photo3.png",
          title: "Affordable Pricing",
        },
        {
          id: 4,
          imgSrc: "images/photo4.png",
          title: "Certified Labs",
        },
      ];
      
  return (
    <>
        <div
      className={`fixed top-0 right-0 rounded-l-xl h-full bg-[#CEE0FF] shadow-lg transform transition-transform duration-500 ease-in-out z-50 ${
        isSheetOpen ? 'translate-x-0' : 'translate-x-full'
      } w-full sm:w-64 md:w-80 lg:w-96 z-50 overflow-y-auto max-h-screen`}
    >
      <div className=" font-museo">
        <div className="px-6 pt-4">
          <div className="text-black text-lg font-semibold">Details</div>
          <button className="absolute top-5 right-8 text-black" onClick={closeSheet}>
            <IoMdClose />
          </button>
          <div className="border-t mb-4 border-gray-400"></div>
        </div>

        {selectedCheckup && (
          <div>
            <div className="px-6 py-4">
              <h3 className="text-lg font-bold text-blue-900">
                <Link to={`/testDetail/${selectedCheckup._id}`}>
                  {selectedCheckup.title} <span className="font6">{selectedCheckup.subTitle}</span>
                </Link>
              </h3>
              <div className="flex justify-between items-center text-sm font7 mb-4 mt-2">
                <span className="">
                  <span className="font6">{parametersLength}</span> Parameters
                </span>
                <span>
                  <span className="font6">{selectedCheckup.reportTime} </span>
                  Reports
                </span>
              </div>
              <div className="flex justify-center items-center mb-4 bg-white rounded-xl p-2 gap-2">
                <div className="text-lg font-semibold font6">
                  ₹{selectedCheckup.price}
                </div>
                <div className="text-sm font-medium text-[#0B234E]">
                  60% off
                </div>
              </div>
              <button
                className="bg-[#DF4132] text-white text-center w-full py-2 rounded-lg hover:bg-red-600"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBuyNow(selectedCheckup._id);
                }}
              >
                {isInCart(selectedCheckup._id) ? (
                  <Link to="/steps" className="w-full block">
                    View Cart
                  </Link>
                ) : (
                  "Buy Now"
                )}
              </button>
              <div className="text-lg mt-6 text-[#2E3192] font-museo font-bold">
                What is it Used For ?
              </div>
              <div className="text-[#666363] text-[16px]">
                {selectedCheckup.usedFor}
              </div>
            </div>
            <div className="band-gradient w-full">
              <div className="flex items-center px-6">
                <div className="text-white font-museo text-sm font-semibold">
                  <p>Home Sample Collection Available</p>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="df" width={"30%"} />
                </div>
              </div>
            </div>
            <div className="px-6">
              <h3 className="font-museo font4 text-lg font-semibold mt-3">
                Package Instructions
              </h3>
              <span className="flex ">
                {" "}
                <img
                  src="others/star-svgrepo-com.svg"
                  alt="df"
                  width="16px"
                />{" "}
                <p className="font-mont font7 text-sm ml-2 font-medium mt-1">
                  {selectedCheckup.instructions}
                </p>
              </span>
            </div>
            <div className="pt-4 mb-6">
              <div className="mx-auto px-6 band-gradient text-white">
                <h3 className="font-museo text-lg font-semibold pb-6 pt-2 text-white">
                  Benefits:
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pb-6">
                  {benefits.map((benefit) => (
                    <div
                      key={benefit.id}
                      className="text-white rounded-lg flex items-center justify-start shadow-lg"
                    >
                      <div className="bg-white rounded-full p-3">
                        <img
                          src={benefit.imgSrc}
                          alt={benefit.title}
                          className="w-4 h-4"
                        />
                      </div>
                      <h3 className="text-sm ml-1 font-mont">
                        {benefit.title}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <IncludedTests2 parameters={filteredParameters}/>
            </div>
          </div>
        )}
      </div>
    </div>
    {isSheetOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={closeSheet}
          ></div>
        )}
    </> 
  );
};

export default SideSheet;