import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

const CheckupSection = () => {
  const [checkups, setCheckups] = useState([]);
  const { filepath } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosClient(`/test-categories/test/categories`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setCheckups(data.testCategory || []);
        // console.log("fetch data", data.testCategory);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/tests/category/${categoryId}`);
  };

  return (
    <div className="max-w-screen-2xl mx-auto lg:py-8 py-4 px-4 sm:px-6 md:mt-8 mt-2 lg:px-20 relative">
      <div className="max-w-screen-2xl md:h-[320px] h-[280px] flex-col">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-black py-6 md:mb-8 text-center font-museo">
          Frequently Booked <span className="font1">Checkups</span>
        </h2>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {checkups.map((checkup) => (
            <SwiperSlide key={checkup._id}>
              <div
                onClick={() => handleCategoryClick(checkup._id)}
                className="flex items-center bg-card1 p-4 sm:px-6 sm:py-4 md:px-3 md:py-3 lg:px-6 lg:py-6  rounded-lg shadow-lg cursor-pointer hover-color1 transition h-[120px] lg:h-[110px]"
              >
                <img
                  src={`${filepath}${checkup.TestImage}`}
                  alt={checkup.name}
                  className="w-12 h-12 lg:w-14 lg:h-14 mr-4 bg-white p-1 rounded-md"
                />
                <div className="flex flex-col">
                  <h3 className="text-white font-semibold text-lg md:text-base font-museo">
                    {checkup.name}
                  </h3>
                  <p className="text-white opacity-80 text-sm font-mont">
                    Total Tests: {checkup.TotalTest}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="button-prev absolute md:bottom-8 bottom-10 right-1/2 mr-1 -translate-x-1/2 lg:left-5 lg:top-1/2 transform lg:-translate-y-1/2 lg:translate-x-0 p-3 w-10 h-10 sm:w-12 sm:h-12 rounded-full border-2 border-color1 font6 hover:bg-color1 hover:text-white transition-all flex items-center justify-center">
        <FiArrowLeft className="text-xl" />
      </div>
      <div className="button-next absolute md:bottom-8 bottom-10 left-1/2 lg:left-auto lg:right-5 -translate-x-1/2 ml-1 lg:top-1/2 transform lg:-translate-y-1/2 lg:translate-x-0 p-3 w-10 h-10 sm:w-12 sm:h-12 rounded-full border-2 border-color1 font6 hover:bg-color1 hover:text-white transition-all flex items-center justify-center">
        <FiArrowRight className="text-xl" />
      </div>
    </div>
  );
};

export default CheckupSection;
