import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import OtpTimer from "otp-timer";
import axiosClient from "../../axios-client";
import Navbar2 from "../layout/Navbar2";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";

const OtpVerification = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const location = useLocation();
  const { mobileNumber } = location.state || {};
  const [otp, setOtp] = useState(new Array(6).fill(""));

  // Handle OTP change for each field
  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next field if a digit is entered
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  // const handleResendOtp = () => {
  //   console.log("resend otp");
  // };
  const handleResendOtp = async () => {
    try {
      const response = await axiosClient.post("users/resend-otp", {
        phone: mobileNumber,
      });

      if (response.data.success) {
        setSuccessMessage("OTP resent successfully!");
        setError("");
      } else {
        setError("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || "Error resending OTP");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    const otpString = otp.join("");

    if (!otpString) {
      setError("OTP is required");
    } else if (otpString.length !== 6) {
      setError("OTP must be 6 digits");
    } else {
      try {
        const response = await axiosClient.post("users/verify-otp", {
          phone: mobileNumber,
          otp: otpString,
        });

        if (response.data.success) {
          setSuccessMessage("OTP Verified successfully!");
          const token = response.data.token;
          if (!token || token.split(".").length !== 3) {
            throw new Error("Invalid token received");
          }
          localStorage.setItem("token", token);
          localStorage.setItem("userId", response.data.user._id);
          navigate("/");
        } else {
          setError("Invalid OTP. Please try again.");
        }
      } catch (error) {
        if (error.response) {
          setError(error.response.data.message || "Invalid OTP");
        } else {
          setError("An error occurred. Please try again.");
        }
      }
    }
  };

  return (
    <div>
      <Navbar />
      <Navbar2 />
      <div className="bg-[#2E319233] w-full flex">
        {/* Left side image */}
        <div className="hidden md:flex items-center justify-center p-20">
          <img
            src="images/loginPage.png"
            alt="OTP Illustration"
            className=" md:w-[600px] img-block pt-10"
          />
        </div>

        {/* Right side form */}
        <div className="w-full lg:w-1/2 px-4 py-24 md:p-16 lg:py-32 lg:px-20  md:shadow-2xl md:rounded-l-3xl bg-white">
          <h2 className="lg:text-4xl text-2xl font1 font-museo font-bold text-center">
            OTP Verification
          </h2>
          <p className="text-center lg:text-lg text-sm font7 lg:my-5 md:my-4 my-2 italic font-mont ">
            Enter the OTP sent to your mobile number
          </p>
          <div className="flex flex-col items-center justify-center w-full">
            <form
              onSubmit={handleVerifyOtp}
              className="lg:space-y-4 space-y-2 w-5/6 font-mont"
            >
              <div>
                <label
                  htmlFor="otp"
                  className="block lg:text-lg font-medium font-mont lg:mb-3 mb-2 pt-3"
                >
                  Enter OTP
                </label>
                <div className="flex lg:space-x-4 md:space-x-2 space-x-3 items-center justify-center">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="tel"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      className="lg:w-11 lg:h-12 w-6 h-7 px-2  lg:px-4 lg:py-3 py-2 border border-color3  rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500 lg:text-base font9 text-sm"
                      pattern="[0-9]*"
                      inputMode="numeric"
                    />
                  ))}
                </div>
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                {successMessage && (
                  <p className="text-green-500 text-sm mt-1">
                    {successMessage}
                  </p>
                )}
              </div>

              <div className=" lg:pt-3">
                <button
                  type="submit"
                  className="w-full bg-card1 text-white font-semibold lg:text-lg text-base lg:py-3 py-2 rounded-lg hover-color1 transition duration-200 font-museo"
                >
                  Verify OTP
                </button>
              </div>
              <span className="text-end">
                <OtpTimer
                  minutes={0}
                  seconds={30}
                  text={
                    <span className="otp-timer-text">Resend OTP In : </span>
                  }
                  ButtonText={
                    <span className="otp-timer-button">Resend OTP</span>
                  }
                  textColor={"#2e3192"}
                  buttonColor={"#2e3192"}
                  background={"#fff"}
                  resend={handleResendOtp}
                />
              </span>
              {/* <div className="text-center font-mont font-medium">
                <p className="lg:text-base text-xs">
                  Already have an account?{" "}
                  <a href="/login" className="font1 hover:underline">
                    Login
                  </a>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OtpVerification;
