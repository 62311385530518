import React from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import StatsSection from "../landingPage/StatsSection";
import WhyChooseUs from "../landingPage/WhyChooseUs";
import BookTest from "../landingPage/BookTest";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import HowWeStarted from "./HowWeStarted";
import KeyFeatures from "./KeyFeatures";
import Banner from "./Banner";

function About() {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />
      <HowWeStarted />
      <StatsSection />
      <KeyFeatures />
      <WhyChooseUs />
      <BookTest />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default About;
