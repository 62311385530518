import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import { Dialog } from "@headlessui/react";
import { FaPlus } from "react-icons/fa";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";

function FamilyMembers() {
  const [members, setMembers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [currentMember, setCurrentMember] = useState({
    name: "",
    age: "",
    gender: "",
    relation: "",
    phone: "",
    email: "",
    dob: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register"); // Redirect if no token
    }
  }, [navigate]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const relationships = [
    "Father",
    "Mother",
    "Son",
    "Daughter",
    "Spouse",
    "Sibling",
    "Self",
  ];

  useEffect(() => {
    fetchFamilyMembers();
  }, []);

  const userId = localStorage.getItem("userId");

  const calculateAge = (dob) => {
    if (!dob) return "";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const fetchFamilyMembers = async () => {
    try {
      const response = await axiosClient.get(`/users/family-member/${userId}`);
      setMembers(response.data.familyMembers);
    } catch (error) {
      console.error("Error fetching family members:", error);
    }
  };

  const handleAddMemberClick = () => {
    setCurrentMember({
      name: "",
      age: "",
      dob: "",
      gender: "",
      relation: "",
      phone: "",
      email: "",
    });
    setIsAdding(true);
  };

  const handleEdit = (index) => {
    setCurrentMember(members[index]);
    setEditIndex(index);
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      if (currentMember._id) {
        await axiosClient.put(`/users/family-member/${currentMember._id}`, {
          ...currentMember,
          userId: localStorage.getItem("userId"),
          age: calculateAge(currentMember.dob).toString(),
        });
      } else {
        await axiosClient.post("/users/family-member", {
          ...currentMember,
          userId: localStorage.getItem("userId"),
        });
      }
      await fetchFamilyMembers();
      setCurrentMember({
        name: "",
        age: "",
        gender: "",
        relation: "",
        phone: "",
        email: "",
        dob: "",
      });
      setIsEditing(false);
      setIsAdding(false);
    } catch (error) {
      console.error("Error saving family member:", error);
    }
  };

  const handleDelete = () => {
    const updatedMembers = members.filter((_, index) => index !== editIndex);
    setMembers(updatedMembers);
    setIsEditing(false);
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "";
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error formatting date for input:", error);
      return "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "dob") {
      const calculatedAge = calculateAge(value);
      setCurrentMember((prev) => ({
        ...prev,
        [name]: value,
        age: calculatedAge.toString(),
      }));
    } else {
      setCurrentMember((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const indexOfLastMember = currentPage * itemsPerPage;
  const indexOfFirstMember = indexOfLastMember - itemsPerPage;
  const currentMembers = members.slice(indexOfFirstMember, indexOfLastMember);
  const totalPages = Math.ceil(members.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const today = new Date();
  today.setDate(today.getDate() - 1); // Subtract 1 day to get yesterday's date
  const yesterdayString = today.toISOString().split("T")[0];

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "";
      
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
  
      return `${day}/${month}/${year}`;
    } catch (error) {
      console.error("Error formatting date for display:", error);
      return "";
    }
  };
  

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4 ">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="w-full md:w-3/4 p-4 md:p-8">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold font-museo font4 pb-5">
                Family Members
              </h1>
            </div>

            <div className="space-y-4">
              {currentMembers.map((member, index) => (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 font-museo shadow-sm bg-gray-50 border-b border-b-red-600"
                >
                  <div className="w-full sm:w-auto mb-4 sm:mb-0">
                    <h3 className="text-lg font-bold font4">
                      {member.name || "New Member"}
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-5">
                      <p className="font7">
                        <span className="font-semibold font6">DOB</span> :{" "}
                        {formatDateForDisplay(member.dob) || "" }
                      </p>
                      <p className="font7">
                        <span className="font-semibold font6">Age</span> :{" "}
                        {member.age || ""}
                      </p>
                      <p className="font7">
                        <span className="font-semibold font6">Gender</span> :{" "}
                        {member.gender || ""}
                      </p>
                      <p className="font7">
                        <span className="font-semibold font6">Relation</span> :{" "}
                        {member.relation || ""}
                      </p>
                      <p className="font7">
                        <span className="font-semibold font6">Phone No.</span> :{" "}
                        {member.phone || ""}
                      </p>
                      <p className="font7">
                        <span className="font-semibold font6">Email</span> :{" "}
                        {member.email || ""}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleEdit(index)}
                    className="bg-card1 text-white px-4 py-2 rounded-md hover:bg-red-600 w-full sm:w-auto mt-4 sm:mt-0"
                  >
                    Edit
                  </button>
                </div>
              ))}
            </div>

            {/* Pagination Controls */}
            {members.length > itemsPerPage && (
              <div className="mt-4 flex justify-between">
                <button
                  onClick={handlePrevious}
                  className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo  ${
                    currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={handleNext}
                  className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo  ${
                    currentPage === totalPages
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}

            <button
              onClick={handleAddMemberClick}
              className="mt-4 bg-card1 font-museo text-white px-4 py-2 rounded-md flex items-center space-x-2 hover:bg-red-600"
            >
              <FaPlus />
              <span>Add More Members</span>
            </button>

            {/* Modal for Adding or Editing Member */}
            <Dialog
              open={isEditing || isAdding}
              onClose={() => {
                setIsEditing(false);
                setIsAdding(false);
              }}
              className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 transition-opacity duration-300"
            >
              <Dialog.Panel className="bg-white rounded-lg shadow-md w-full max-w-lg mx-4 max-h-[90vh] overflow-y-auto">
                <Dialog.Title className="text-xl font-bold mb-4 font-museo border-b-2 px-4 sm:px-6 py-4">
                  {isEditing ? "Edit Patient" : "Add New Patient"}
                </Dialog.Title>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mx-4 sm:mx-6">
                  <div className="col-span-1 sm:col-span-2">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      name="name"
                      value={currentMember.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                      className="w-full p-2 border rounded-lg"
                      required
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="relation"
                    >
                      Relation
                    </label>
                    <select
                      name="relation"
                      id="relation"
                      value={currentMember?.relation || ""}
                      onChange={handleInputChange}
                      placeholder="Relation"
                      className="w-full p-2 border rounded-lg"
                    >
                      <option value="">Select Relationship</option>
                      {relationships.map((relation) => (
                        <option key={relation} value={relation}>
                          {relation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="dob"
                    >
                      Date of Birth
                    </label>
                    <input
                      name="dob"
                      type="date"
                      value={formatDateForInput(currentMember.dob)}
                      onChange={handleInputChange}
                      placeholder="DD/MM/YYYY"
                      className="w-full p-2 border rounded-lg"
                      required
                      max={yesterdayString}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="age"
                    >
                      Age (Years)
                    </label>
                    <input
                      name="age"
                      value={currentMember.age}
                      onChange={handleInputChange}
                      placeholder="Age"
                      className="w-full p-2 border rounded-lg"
                      readOnly
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="gender"
                    >
                      Gender
                    </label>
                    <select
                      name="gender"
                      value={currentMember.gender}
                      onChange={handleInputChange}
                      placeholder="Gender"
                      className="w-full p-2 border rounded-lg"
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <input
                      name="phone"
                      value={currentMember.phone}
                      onChange={handleInputChange}
                      placeholder="Phone"
                      className="w-full p-2 border rounded-lg"
                      pattern="^\d{10}$" // Allows only 10 digits
                      maxLength="10" // Restricts input to a maximum of 10 characters
                      title="Phone number should be exactly 10 digits"
                      minLength="10"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      value={currentMember.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between mt-4 border-t-2 p-4 sm:p-6">
                  <button
                    onClick={handleSave}
                    className="bg-card1 text-white px-4 py-2 rounded-md hover:bg-red-600 font-museo mb-2 sm:mb-0"
                  >
                   Save
                  </button>
                  <button
                    onClick={() => {
                      setIsEditing(false);
                      setIsAdding(false);
                    }}
                    className="bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Dialog>
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default FamilyMembers;
