import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Banner from "./TermsBanner";
import axiosClient from "../../axios-client";

function Terms() {
  // const [info, setInfo] = useState();
  const [content, setContent] = useState("");

  // useEffect(() => {
  //   const fetchInfo = async () => {
  //     try {
  //       const response = await axiosClient.get("details");

  //       if (response.data.success) {
  //         setInfo(response.data.generalDetails);
  //         console.log("Info",response.data.generalDetails)
  //       } else {
  //         throw new Error("Failed to fetch info.");
  //       }
  //     } catch (err) {
  //       console.error(err.message || "Failed to fetch info. Please try again.");
  //     }
  //   };

  //   fetchInfo();
  // }, []);

  useEffect(() => {
    const fetchTerms = async () => {
      const response = await axiosClient.get("/terms");
      setContent(response.data.terms.content || "");
    };

    fetchTerms();
  }, []);
  // console.log(content);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />
      {/* <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 mx-auto max-w-screen-2xl font-mont">
        <h1 className="md:text-3xl text-2xl font-bold mb-6">
          Terms and Conditions
        </h1>

        <p
          className="md:pb-6 pb-4"
          style={{ lineHeight: "2", textAlign: "justify" }}
        >
          Welcome to Dr. Palve Hospital’s diagnostic service website, accessible  at{" "}
          <a href="https://labs.drpalvehospital.com/" className="text-blue-500">
            https://labs.drpalvehospital.com
          </a>
          . By accessing and using our services, you agree to the following
          terms and conditions.
        </p>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          1. Acceptance of Terms
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            By using this Website, you agree to comply with these Terms and
            Conditions. If you do not agree, please refrain from using the
            Website and its services.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          2. Eligibility
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            You must be at least 18 years old to use the Website or avail our
            services. If you are under 18, you must use the Website under the
            supervision of a parent or guardian.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          3. Services Offered
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            Dr. Palve Hospital provides diagnostic and laboratory testing
            services through its Website. The content and information on the
            Website, including medical information, is for general guidance and
            does not substitute professional medical advice.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          4. User Accounts
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            To access certain features, you may be required to create an
            account. You are responsible for maintaining the confidentiality of
            your account credentials and for all activities under your account.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          5. Booking and Payments
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            By booking any diagnostic tests through the Website:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span>
                You agree to provide accurate and complete personal and health
                information.
              </span>
            </li>
            <li className="mt-1">
              <span>
                You authorize us to charge the payment method provided for any
                purchases..
              </span>
            </li>
            <li className="mt-1">
              <span>
                You agree that the prices for services are subject to change
                without notice.
              </span>
            </li>
          </ul>
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We use third-party payment gateways to process transactions
            securely. Please review their terms before completing the
            transaction.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          6. Cancellations and Refunds
        </h3>
        <div className="ml-6">
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Cancellations: </span>
              <span>
                You may cancel a booked service, provided that you do so before
                the sample collection or appointment time.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Refunds: </span>
              <span>
                Refunds will be provided according to our cancellation policy.
                Any refund process may take up to 10 business days, depending on
                your payment provider.
              </span>
            </li>
          </ul>
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We use third-party payment gateways to process transactions
            securely. Please review their terms before completing the
            transaction.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          7. Medical Disclaimer
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            The services offered on this Website are not intended as medical
            advice and should not replace consultations with healthcare
            professionals. The information and reports provided are solely for
            your reference and should be reviewed with your doctor.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          9. Third-Party Links
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            The Website may contain links to external websites. These links are
            provided for convenience, and we are not responsible for the content
            or policies of third-party websites.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          10. Limitation of Liability
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            Dr. Palve Hospital will not be liable for any direct, indirect,
            incidental, or consequential damages arising from the use or
            inability to use the Website or its services, including errors,
            omissions, or delays in content.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          11. Indemnification
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            You agree to indemnify, defend, and hold harmless Dr. Palve
            Hospital, its affiliates, and employees from any claims, damages, or
            liabilities arising out of your use of the Website or breach of
            these Terms.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          12. Termination of Access
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            We reserve the right to suspend or terminate your access to the
            Website without notice for any violation of these Terms or misuse of
            the Website.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          13. Governing Law
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            These Terms shall be governed and construed in accordance with the
            laws of Maharashtra State. Any disputes related to these Terms will
            be resolved exclusively in the courts located in Maharashtra State.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          14. Changes to Terms and Conditions
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            Dr. Palve Hospital reserves the right to modify these Terms at any
            time. We will notify users of significant changes, and continued use
            of the Website after such changes constitutes acceptance of the new
            terms.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          15. Contact Information
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            If you have any questions or concerns regarding this Privacy Policy,
            or if you would like to file a complaint, please contact us at:
          </p>
          <ul className="list-disc pl-6 mb-6">
          <li className="mt-1">
              <span className="font-semibold">Email: </span>
              <span>
                <a
                  href={`mailto:${info?.supportEmail}`}
                  className="text-blue-500"
                >
                  {info?.supportEmail}
                </a>
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Phone: </span>
              <span>{info?.phoneNumber}</span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Address: </span>
              <span>
                Dr Palve Multispecialty Hospital, Chandan Nagar, Kharadi, Pune,
                Maharashtra - 411014.
              </span>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 mx-auto max-w-screen-2xl font-mont">
        <div
          className="terms-content"
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default Terms;
