import React, { useEffect, useMemo, useState } from "react";
import Navbar from "./layout/Navbar";
import Navbar2 from "./layout/Navbar2";
import NewsLetter from "./landingPage/NewsLetter";
import Footer from "./layout/Footer";
import axiosClient from "../axios-client";
import FilterModal from "./FilterModal"; // Adjust the import path accordingly
import SortModal from "./SortModal"; // Adjust the import path accordingly
import Description from "./Description";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useParams } from "react-router-dom";
import SideSheet from "./SideSheet";

function Tests() {
  const [visibleCards, setVisibleCards] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tests, setTests] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryLookup, setCategoryLookup] = useState({});
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [priceRange, setPriceRange] = useState({ min: "", max: "" });
  const [tempPriceRange, setTempPriceRange] = useState({ min: "", max: "" });
  const { addToCart, isInCart } = useAuth();
  const { categoryId, labId } = useParams();
  const [info, setInfo] = useState(null);
  const [priceError, setPriceError] = useState("");
  const [selectedCheckup, setSelectedCheckup] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [parametersCount, setParametersCount] = useState({});

  const handleTestTitleClick = (test) => {
    setSelectedCheckup(test);
    setIsSheetOpen(true);
  };

  const closeSheet = () => {
    setIsSheetOpen(false);
  };

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axiosClient.get("details");
        if (response.data.success) {
          const details = response.data.generalDetails;
          setInfo(details);

          // Initialize price ranges with actual values
          const initialPriceRange = {
            min: details.minPrice.toString(),
            max: details.maxPrice.toString(),
          };

          setPriceRange(initialPriceRange);
          setTempPriceRange(initialPriceRange);
        }
      } catch (err) {
        console.error("Failed to fetch info:", err);
      }
    };

    fetchInfo();
  }, []);

  const validatePriceRange = (min, max) => {
    const minPrice = Number(min);
    const maxPrice = Number(max);
    const minLimit = info?.minPrice || 0;
    const maxLimit = info?.maxPrice || Infinity;

    if (min !== "" && minPrice < minLimit) {
      return `Minimum price cannot be less than ${minLimit}`;
    }
    if (max !== "" && maxPrice > maxLimit) {
      return `Maximum price cannot exceed ${maxLimit}`;
    }
    if (min !== "" && max !== "" && minPrice > maxPrice) {
      return "Minimum price cannot be greater than maximum price";
    }
    return "";
  };

  const handleBuyNow = (testId) => {
    addToCart(testId);
  };

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch tests for the specific lab if labId is provided, otherwise fetch all tests
        let testsRes;
        let testData = [];
        if (labId) {
          testsRes = await axiosClient.get(`/tests/test/lab/${labId}`);
          setTests(testsRes.data.data || []);
        } else {
          testsRes = await axiosClient.get("/tests/tests");
          testData = testsRes.data.test || [];
          setTests(testData);
        }

        // Fetch categories
        const categoriesRes = await axiosClient.get(
          "/test-categories/test/categories"
        );
        const categoryData = categoriesRes.data.testCategory || [];
        setCategories(categoryData);

        // Create category lookup
        const lookup = {};
        categoryData.forEach((category) => {
          lookup[category._id] = category.name;
        });
        setCategoryLookup(lookup);

        // If categoryId is present in the URL, set it as the selected category
        if (categoryId) {
          setSelectedCategories([categoryId]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [categoryId, labId]);

  useEffect(() => {
    const fetchParametersCount = async () => {
      try {
        const promises = tests.map(async (test) => {
          try {
            const response = await axiosClient.get(`/tests-parameters/test/parameters/test/${test._id}`);
  
            // Check if the response is valid and contains totalParametersCount
            if (response.status === 404 || !response.data || response.data.totalParametersCount === undefined) {
              return {
                testId: test._id,
                count: 0
              };
            }
            return {
              testId: test._id,
              count: response.data.totalParametersCount || 0
            };
          } catch (error) {
            console.error(`Error fetching parameters for test ${test._id}:`, error);
            return {
              testId: test._id,
              count: 0 // Default to 0 in case of an error
            };
          }
        });
  
        const results = await Promise.all(promises);
  
        const countsMap = results.reduce((acc, { testId, count }) => {
          acc[testId] = count; // Mapping testId to count
          return acc;
        }, {});
  
        setParametersCount(countsMap); // Set the mapped parameter counts
      } catch (error) {
        console.error("Error fetching parameters count:", error);
      }
    };
  
    if (tests.length > 0) {
      fetchParametersCount();
    }
  }, [tests]);
  
  

  // Search function
  const searchFilter = (test) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      test.title?.toLowerCase().includes(searchTermLower) ||
      test.subTitle?.toLowerCase().includes(searchTermLower) ||
      test.description?.toLowerCase().includes(searchTermLower)
    );
  };

  // Category filter
  const categoryFilter = (test) => {
    if (selectedCategories.length === 0) return true;
    return selectedCategories.includes(test.testCategory);
  };

  // Price filter
  const priceFilter = (test) => {
    const min =
      priceRange.min === "" ? info?.minPrice || 0 : Number(priceRange.min);
    const max =
      priceRange.max === ""
        ? info?.maxPrice || Infinity
        : Number(priceRange.max);
    return test.price >= min && test.price <= max;
  };
  // Sort function
  const getSortedData = (data) => {
    switch (sortBy) {
      case "price-low":
        return [...data].sort((a, b) => a.price - b.price);
      case "price-high":
        return [...data].sort((a, b) => b.price - a.price);
      case "popularity":
        return [...data].sort((a, b) => b.parameters - a.parameters);
      case "recent":
        return [...data].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      default:
        return data;
    }
  };

  // Apply all filters and sorting
  const filteredData = useMemo(() => {
    return getSortedData(
      tests.filter(
        (test) =>
          searchFilter(test) && categoryFilter(test) && priceFilter(test)
      )
    );
  }, [tests, searchTerm, selectedCategories, priceRange, sortBy]);

  // Handle category selection
  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prev) => {
      if (prev.includes(categoryId)) {
        return prev.filter((id) => id !== categoryId);
      }
      return [...prev, categoryId];
    });
  };

  // Handle price range change
  const handlePriceRangeChange = (type, value) => {
    if (value === "" || /^\d*$/.test(value)) {
      const newTempRange = {
        ...tempPriceRange,
        [type]: value,
      };
      setTempPriceRange(newTempRange);

      // Validate and set error
      const error = validatePriceRange(
        type === "min" ? value : tempPriceRange.min,
        type === "max" ? value : tempPriceRange.max
      );
      setPriceError(error);
    }
  };

  const applyFilters = () => {
    const error = validatePriceRange(tempPriceRange.min, tempPriceRange.max);
    if (error) {
      setPriceError(error);
      return;
    }

    setPriceRange(tempPriceRange);
    setPriceError("");
    setIsFilterModalOpen(false);
  };

  const isApplyDisabled = Boolean(
    validatePriceRange(tempPriceRange.min, tempPriceRange.max)
  );

  // Reset filters function
  const resetFilters = () => {
    setSelectedCategories([]);
    const initialPriceRange = {
      min: info?.minPrice.toString() || "",
      max: info?.maxPrice.toString() || "",
    };
    setPriceRange(initialPriceRange);
    setTempPriceRange(initialPriceRange);
    setPriceError("");
  };
  // Reset sort function
  const resetSort = () => {
    setSortBy("");
  };

  const getParametersCount = (testId) => {
    // Access the count from parametersCount state
    const count = parametersCount[testId];
    return count !== undefined ? count : 0; // Default to 0 if not found
  };
  
  
  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        {/* Search and Filter/Sort */}
        <div className="flex justify-center items-center space-x-4 mb-8">
          <div className="relative w-2/3">
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/images/search.png`}
                alt="Search"
                width="18px"
                height="18px"
                className="absolute left-3 top-3 sm:top-5"
              />
              <input
                type="text"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full py-2 sm:py-4 pl-9 pr-14 font-mont rounded-md border border-gray-300 shadow-md focus:outline-none focus:border-blue-500"
              />
            </span>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setIsFilterModalOpen(true)}
              className="p-3 bg-white rounded-full border-color2 shadow-lg shadow-blue-200 hover:bg-gray-200"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/filter.png`}
                alt="Filter"
                width="18px"
                height="18px"
              />
            </button>
            <button
              onClick={() => setIsSortModalOpen(true)}
              className="p-3 bg-white rounded-full border-color2 shadow-md shadow-blue-200 hover:bg-gray-200"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/sort.png`}
                alt="Sort"
                width="18px"
                height="18px"
              />
            </button>
          </div>
        </div>

        {/* Display Selected Category */}
        {categoryId && (
          <div className="mb-4 text-start">
            <h2 className="text-2xl font-bold font-museo font4">
              Showing Tests for{" "}
              {`"${categoryLookup[categoryId]}"` || "Selected Category"}
            </h2>
          </div>
        )}

        {/* Modals */}
        {isFilterModalOpen && (
          <FilterModal
            categories={categories}
            selectedCategories={selectedCategories}
            handleCategoryChange={handleCategoryChange}
            tempPriceRange={tempPriceRange}
            handlePriceRangeChange={handlePriceRangeChange}
            resetFilters={resetFilters}
            applyFilters={applyFilters}
            closeModal={() => setIsFilterModalOpen(false)}
            isApplyDisabled={isApplyDisabled}
            priceError={priceError}
            minAllowed={info?.minPrice}
            maxAllowed={info?.maxPrice}
          />
        )}
        {isSortModalOpen && (
          <SortModal
            sortBy={sortBy}
            setSortBy={setSortBy}
            resetSort={resetSort}
            closeModal={() => setIsSortModalOpen(false)}
          />
        )}

        {/* Test Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {filteredData.slice(0, visibleCards).map((test, index) => (
            <div
              key={test._id || index} // Use a unique key if available
              className="max-w-md rounded-2xl shadow-lg border border-gray-200 p-6"
            >
              <h2
                className="text-lg font-bold font4 font-museo"
                onClick={() => handleTestTitleClick(test)}
              >
                {test.title}
                {test.subTitle && (
                  <span className="font6"> {test.subTitle}</span>
                )}
              </h2>

              <Description text={test.description} />

              <div className="mt-4 text-sm">
                <p className="font7 font-mont">
                  <span className="font-bold font6">Category:</span>{" "}
                  {categoryLookup[test.testCategory] || "Unknown"}
                </p>
                <div className="grid grid-cols-2 mt-2">
                  <span className="mr-4 font6 font-semibold font-mont">
                  {getParametersCount(test._id)} <span className="font7">Parameters</span>
                  </span>
                  <span className="mr-4 font6 font-semibold font-mont">
                    {test.reportTime} <span className="font7">Reports</span>
                  </span>
                  <span className="mr-4 font6 font-semibold font-mont">
                    {test.fastingTime} <span className="font7">Fasting</span>
                  </span>
                  <span className="font6 font-semibold font-mont">
                    {test.tests} 10 <span className="font7">Tests</span>
                  </span>
                </div>
              </div>

              <div className="mt-4 flex justify-between items-center">
                <div className="bg-[#1E4FA642] px-4 lg:py-1 py-2 rounded-lg flex">
                  <p className="font6 font-mont font-medium lg:text-lg text-base">
                    ₹{test.price}
                  </p>
                  {test.discount && (
                    <p className="ml-2 font5 text-base">{test.discount}</p>
                  )}
                </div>

                <button
                  className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs transition-all w-[30%]"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent opening the details sheet
                    handleBuyNow(test._id);
                  }}
                >
                  {isInCart(test._id) ? (
                    <Link to="/steps" className="w-full block">
                      View Cart
                    </Link>
                  ) : (
                    "Buy Now"
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* View More Button */}
        {visibleCards < filteredData?.length && (
          <div className="flex justify-center mt-8">
            <button
              onClick={() => setVisibleCards((prev) => prev + 6)}
              className="bg-card1 font-museo text-white rounded-lg px-6 py-2 font-semibold hover:bg-red-600 transition duration-200"
            >
              View More
            </button>
          </div>
        )}
      </div>
      <NewsLetter />
      <Footer />
      <SideSheet
        selectedCheckup={selectedCheckup}
        isSheetOpen={isSheetOpen}
        closeSheet={closeSheet}
      />
    </>
  );
}

export default Tests;
