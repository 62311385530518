import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import axiosClient from "../../axios-client";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    age: "",
    dob: "",
    phone: "",
    email: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register");
    }
  }, [navigate]);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchUserData();
  }, []);

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "";
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date for display:", error);
      return "";
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "";
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error formatting date for input:", error);
      return "";
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axiosClient.get(`users/details/${userId}`);
      const userData = response.data.user;

      // Create a copy of userData to avoid direct mutations
      const processedUserData = { ...userData };

      if (userData.dob && userData.dob !== "false") {
        try {
          const dobDate = new Date(userData.dob);

          if (!isNaN(dobDate.getTime())) {
            processedUserData.dob = dobDate.toISOString().split("T")[0];
            processedUserData.age = calculateAge(processedUserData.dob);
          } else {
            processedUserData.dob = "";
            processedUserData.age = "";
          }
        } catch (dateError) {
          console.error("Error processing date:", dateError);
          processedUserData.dob = "";
          processedUserData.age = "";
        }
      } else {
        processedUserData.dob = "";
        processedUserData.age = "";
      }

      setUserInfo(processedUserData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data. Please try again.");
    }
  };

  const calculateAge = (dob) => {
    try {
      const birthDate = new Date(dob);
      const today = new Date();

      if (isNaN(birthDate.getTime())) {
        return "";
      }

      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();

      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    } catch (error) {
      console.error("Error calculating age:", error);
      return "";
    }
  };

  const handleClose = () => {
    setShowEdit(false);
    setError("");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // Create a copy of userInfo
      const formData = { ...userInfo };

      if (formData.dob) {
        const dobDate = new Date(formData.dob);
        if (!isNaN(dobDate.getTime())) {
          formData.dob = dobDate.toISOString().split("T")[0];
        }
      }

      formData.age = calculateAge(formData.dob);

      Object.keys(formData).forEach((key) => {
        if (formData[key] === null || formData[key] === undefined) {
          delete formData[key];
        }
      });

      const response = await axiosClient.put(
        `users/details/${userId}`,
        formData
      );

      if (response.data) {
        setShowEdit(false);
        setError("");
        await fetchUserData();
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      setError(
        error.response?.data?.message ||
          "Failed to update user data. Please try again."
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const today = new Date();
  today.setDate(today.getDate() - 1); // Subtract 1 day to get yesterday's date
  const yesterdayString = today.toISOString().split('T')[0]; 

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex flex-grow border-2 border-gray-300 shadow-lg rounded-3xl">
          <Sidebar />

          <main className="w-3/4 p-8">
            <div className="flex justify-between items-center">
              <h1 className="lg:text-2xl md:text-xl text-lg font-semibold font-museo font4 mr-2">
                Personal Information
              </h1>
              <button
                onClick={() => setShowEdit(true)}
                className="bg-white font1 border-2 border-color2 px-3 py-1 rounded"
              >
                Edit
              </button>
            </div>

            <div className="mt-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block font-mont font7 font-semibold">
                    First Name
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {userInfo?.firstName}
                  </p>
                </div>
                <div>
                  <label className="block font-mont font7 font-semibold">
                    Last Name
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {userInfo?.lastName}
                  </p>
                </div>
                <div>
                  <label className="block font-mont font7 font-semibold">
                    DOB
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {formatDateForDisplay(userInfo?.dob)}
                  </p>
                </div>
                <div>
                  <label className="block font-mont font7 font-semibold">
                    Gender
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {userInfo?.gender}
                  </p>
                </div>
                <div>
                  <label className="block font-mont font7 font-semibold">
                    Phone
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {userInfo?.phone}
                  </p>
                </div>
                <div>
                  <label className="block font-mont font7 font-semibold">
                    Email
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {userInfo?.email}
                  </p>
                </div>
                <div>
                  <label className="block font-mont font7 font-semibold">
                    Age
                  </label>
                  <p className="font-medium font6 text-lg font-mont">
                    {userInfo?.age}
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Edit User Modal */}
      <Modal show={showEdit} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdate}>
            {error && <div className="alert alert-danger">{error}</div>}

            <div className="mb-3">
              <label className="block font-mont font7 font-semibold">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={userInfo.firstName}
                onChange={handleChange}
                className="w-full p-3 font7 font-mont font-medium border-b-2 border-red-600 bg-gray-100"
                required
              />
            </div>

            <div className="mb-3">
              <label className="block font-mont font7 font-semibold">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={userInfo.lastName}
                onChange={handleChange}
                className="w-full p-3 font7 font-mont font-medium border-b-2 border-red-600 bg-gray-100"
              />
            </div>

            <div className="mb-3">
              <label className="block font-mont font7 font-semibold">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={userInfo.email}
                onChange={handleChange}
                className="w-full p-3 font7 font-mont font-medium border-b-2 border-red-600 bg-gray-100"
                required
              />
            </div>

            <div className="mb-3">
              <label className="block font-mont font7 font-semibold">
                Date of Birth
              </label>
              <input
                type="date"
                name="dob"
                value={formatDateForInput(userInfo.dob)}
                placeholder="DD/MM/YYYY"
                onChange={handleChange}
                className="w-full p-3 font7 font-mont font-medium border-b-2 border-red-600 bg-gray-100"
                max={yesterdayString}
                required
              />
            </div>

            <div className="mb-3">
              <label className="block font-mont font7 font-semibold">
                Gender
              </label>
              <select
                name="gender"
                value={userInfo.gender}
                onChange={handleChange}
                className="w-full p-3 font7 font-mont font-medium border-b-2 border-red-600 bg-gray-100"
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={handleClose}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-red-600 text-white px-4 py-2 rounded"
              >
                Save Changes
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <NewsLetter />
      <Footer />
    </>
  );
};

export default ProfilePage;
