import React from "react";

const FilterModal = ({
  categories,
  selectedCategories,
  handleCategoryChange,
  tempPriceRange,
  handlePriceRangeChange,
  resetFilters,
  applyFilters,
  closeModal,
  isApplyDisabled,
  priceError,
  minAllowed,
  maxAllowed
}) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div className="bg-white md:w-[40%] w-56 rounded-lg shadow-lg relative">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 w-5"
        onClick={closeModal}
      >
        &times;
      </button>

      <h2 className="text-xl font-semibold mb-4 border-b-2 font-museo py-4 px-4">
        Filters
      </h2>

      <div className="mb-4 px-6 font-museo md:text-base text-sm">
        <h3 className="md:text-lg text-base font-semibold mb-2">Category</h3>
        <div className="flex flex-col space-y-2">
          {categories.map((category) => (
            <label key={category._id} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={selectedCategories.includes(category._id)}
                onChange={() => handleCategoryChange(category._id)}
                className="form-checkbox"
              />
              <span>{category.name}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="mb-4 px-6">
        <h3 className="md:text-lg text-base font-semibold mb-2 font-museo">
          Price Range
        </h3>
        <div className="flex space-x-2 font-mont">
          <input
            type="number"
            placeholder={`Min (${minAllowed})`}
            value={tempPriceRange.min}
            onChange={(e) => handlePriceRangeChange("min", e.target.value)}
            className="w-full p-2 border rounded-lg md:text-base text-sm"
          />
          <input
            type="number"
            placeholder={`Max (${maxAllowed})`}
            value={tempPriceRange.max}
            onChange={(e) => handlePriceRangeChange("max", e.target.value)}
            className="w-full p-2 border rounded-lg md:text-base text-sm"
          />
        </div>
        {priceError && (
            <p className="text-red-500 text-sm mt-1">{priceError}</p>
          )}
      </div>

      <div className="flex justify-between mt-6 border-t-2 py-4 px-6 font-museo md:text-base text-sm">
        <button
          onClick={resetFilters}
          className="bg-secondary1 text-white md:px-4 md:py-2 px-2 py-1 rounded-lg"
        >
          Reset
        </button>
        <button
          onClick={applyFilters}
          disabled={isApplyDisabled}
          className={`bg-card1 text-white md:px-4 md:py-2 px-2 py-1 rounded-lg ${
            isApplyDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Apply Filter
        </button>
      </div>
    </div>
  </div>
);

export default React.memo(FilterModal);
