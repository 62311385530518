import React from "react";

function Band() {
  return (
    <div className="max-w-screen-2xl mx-auto band-gradient ">
      <div className="lg:px-20 md:px-12 px-4 flex justify-between items-center">
        <div className="text-white font-museo lg:text-xl md:text-lg text-sm font-semibold">
          <h5>Home Sample Collection Available</h5>
        </div>
        <div className="flex items-center justify-end">
          <img src={`${process.env.PUBLIC_URL}/others/nurse.png`} alt="df" width={"25%"} />
        </div>
      </div>
    </div>
  );
}

export default Band;
