import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner, Card } from 'react-bootstrap';
import { FileText, Download, Trash2, Upload, Eye } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import axiosClient from '../../axios-client';
import iziToast from 'izitoast';

const ReportHandler = ({ id, testId }) => {
  const [reportFile, setReportFile] = useState(null);
  const [report, setReport] = useState(null);
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState("");
  const [filePath, setFilePath] = useState(null);
  const { filepath } = useAuth();


  const fetchReport = async () => {
    try {
      const bookingResponse = await axiosClient.get(`/tests-booking/test/booking/${id}`);

      if (bookingResponse.data.success) {
        const reportFiles = bookingResponse.data.booking.progress.reportFiles;
        const reportFileData = reportFiles.find(file => file.testId === testId);
        if (reportFileData) {
          setFilePath(reportFileData.filePath);
          setReport({
            fileName: reportFileData.filePath,
            blob: null,
          });
        }
      }
    } catch (err) {
      setReportError(err.response?.data?.message || "Failed to fetch report.");
    } finally {
      setReportLoading(false);
    }
  };


  useEffect(() => {

    fetchReport();
  }, [id, testId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setReportFile(file);
  };

  const handleUploadReport = async () => {
    const formData = new FormData();
    formData.append("bookingId", id);
    formData.append("testId[]", testId);
    formData.append("reportFile", reportFile);

    try {
      let response;
      if (report) {
        // Update existing report
        response = await axiosClient.put("/report/edit", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        // Create new report
        response = await axiosClient.post("/report/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.data.success) {
        iziToast.success({
          message: (report ? "Report updated successfully" : "Report uploaded successfully"),
          position: "topCenter"
        });
        setReport({
          fileName: reportFile.name,
          blob: null,
        });
        setReportFile(null);
        fetchReport();
      }
    } catch (err) {
      iziToast.error({
        message: "Failed to upload report",
        position: "topCenter"
      });
    }
  };

  const handleDeleteReport = async () => {
    try {
      const response = await axiosClient.delete(`/report/delete`, {
        data: {
          bookingId: id,
          testId: [testId],
        },
      });

      if (response.data.success) {
        iziToast.success({
          message: "Report deleted successfully",
          position: "topCenter"
        });
        setReport(null);
        setFilePath(null);
      }
    } catch (err) {
      iziToast.error({
        message: "Failed to delete report",
        position: "topCenter"
      });
    }
  };

  const handleDownloadReport = async () => {
    if (!filePath) return;
  
    try {
      // Make a request to the server to get the file blob
      const response = await axiosClient.get(`${filepath}/reports/${filePath}`, {
        responseType: 'blob', // Make sure to get the response as a blob
      });
  
      // Create a blob URL for the file
      const blob = new Blob([response.data], { type: response.data.type });
      const downloadUrl = window.URL.createObjectURL(blob);
  
      // Create an anchor element and trigger download
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = filePath; // The filename you want for the downloaded file
      document.body.appendChild(a);
      a.click();
  
      // Clean up by revoking the object URL after download
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
      iziToast.error({
        message: "Failed to download report.",
        position: "topCenter"
      });
    }
  };

  const handlePreview = () => {
    if (filePath) {
      window.open(`${filepath}/reports/${filePath}`, '_blank');
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Report Management</Card.Title>

        {reportLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : report ? (
          <div>
            <div className="d-flex align-items-center mb-3 gap-2">
              <FileText size={20} />
              <span className="flex-grow-1">{report.fileName}</span>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={handlePreview}
                className="d-flex align-items-center gap-1"
              >
                <Eye size={16} /> Preview
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={handleDownloadReport}
                className="d-flex align-items-center gap-1"
              >
                <Download size={16} /> Download
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={handleDeleteReport}
                className="d-flex align-items-center gap-1"
              >
                <Trash2 size={16} /> Delete
              </Button>
            </div>

            <div className='flex justify-start items-center gap-[10px]'>
              <Form.Group className="mb-3 mt-3">
                <Form.Control
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={handleFileChange}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={handleUploadReport}
                disabled={!reportFile}
                className="d-flex align-items-center gap-1"
              >
                <Upload size={16} /> Update Report
              </Button>
            </div>
          </div>
        ) : (
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                type="file"
                accept=".pdf,.doc,.docx,.txt"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleUploadReport}
              disabled={!reportFile}
              className="d-flex align-items-center gap-1"
            >
              <Upload size={16} /> Upload Report
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default ReportHandler;
