import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { Trash2 } from "lucide-react";

const EditTestParameter = () => {
  const { id } = useParams();
  const [test, setTest] = useState({});
  const [testParameters, setTestParameters] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        const response = await axiosClient.get(`/tests/test/${id}`);
        setTest(response.data.test);
      } catch (error) {
        console.error("Error fetching test:", error);
      }
    };

    const fetchTestParameters = async () => {
      try {
        const response = await axiosClient.get("/tests-parameters/test/parameters");
        const parametersData = response.data.find(
          (param) => param.testId === id
        );
        setTestParameters(parametersData ? parametersData.testParameters : []);
      } catch (error) {
        console.error("Error fetching test parameters:", error);
      }
    };

    fetchTest();
    fetchTestParameters();
  }, [id]);

  const handleTitleChange = (index, value) => {
    const newParameters = [...testParameters];
    newParameters[index].title = value;
    setTestParameters(newParameters);
  };

  const handleParameterChange = (titleIndex, paramIndex, value) => {
    const newParameters = [...testParameters];
    newParameters[titleIndex].parameters[paramIndex] = value;
    setTestParameters(newParameters);
  };

  const addTitle = () => {
    setTestParameters([
      ...testParameters,
      { title: "", parameters: [""], isNew: true },
    ]);
  };

  const addParameter = (index) => {
    const newParameters = [...testParameters];
    newParameters[index].parameters.push("");
    setTestParameters(newParameters);
  };

  // Updated function to remove a parameter
  const removeParameter = async (titleIndex, paramIndex) => {
    const parameter = testParameters[titleIndex];
    
    if (parameter._id && !parameter.isNew) {
      try {
        // Remove the specific parameter from the parameters array
        const updatedParameters = [...parameter.parameters];
        updatedParameters.splice(paramIndex, 1);
        
        // If there are still parameters left, update the parameter group
        if (updatedParameters.length > 0) {
          await axiosClient.put(
            `/tests-parameters/test/parameter/${parameter._id}`,
            {
              testParameters: [
                {
                  title: parameter.title,
                  parameters: updatedParameters,
                },
              ],
            }
          );
        }
      } catch (error) {
        console.error("Error updating parameters:", error);
        alert(`Error updating parameters: ${error.message}`);
        return;
      }
    }

    // Update local state
    const newParameters = [...testParameters];
    newParameters[titleIndex].parameters.splice(paramIndex, 1);
    // If no parameters left, add an empty one
    if (newParameters[titleIndex].parameters.length === 0) {
      newParameters[titleIndex].parameters = [""];
    }
    setTestParameters(newParameters);
  };

  // Updated function to remove a title and its parameters
  const removeTitle = async (index) => {
    const parameterToRemove = testParameters[index];
    
    if (parameterToRemove._id && !parameterToRemove.isNew) {
      try {
        await axiosClient.delete(
          `/api/v1/tests-parameters/test/parameter/${parameterToRemove._id}`
        );
      } catch (error) {
        console.error("Error deleting parameter:", error);
        alert(`Error deleting parameter: ${error.message}`);
        return;
      }
    }

    const newParameters = testParameters.filter((_, idx) => idx !== index);
    setTestParameters(newParameters);
  };

  const updateParameters = async () => {
    try {
      // Handle new parameters
      const newParams = testParameters.filter((param) => param.isNew);
      if (newParams.length > 0) {
        await axiosClient.post("/tests-parameters/test/parameter/add", {
          testId: id,
          testParameters: newParams.map((param) => ({
            title: param.title,
            parameters: param.parameters.filter((p) => p !== ""),
          })),
        });
      }

      // Handle existing parameters updates
      const existingParams = testParameters.filter((param) => !param.isNew);
      for (let param of existingParams) {
        if (param._id) {
          await axiosClient.put(
            `/tests-parameters/test/parameter/${param._id}`,
            {
              testParameters: [
                {
                  title: param.title,
                  parameters: param.parameters.filter((p) => p !== ""),
                },
              ],
            }
          );
        }
      }

      // Refresh the parameters list
      const response = await axiosClient.get("/tests-parameters/test/parameters");
      const parametersData = response.data.find((param) => param.testId === id);
      setTestParameters(parametersData ? parametersData.testParameters : []);

      alert("Parameters updated successfully");
    } catch (error) {
      console.error("Error updating parameters:", error);
      alert(`Error updating parameters: ${error.message}`);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Edit Parameters for Test: {test?.title}</h5>
            </div>
            <div className="flex justify-between p-2">
              <div className="add-button2">
                <button type="button" onClick={addTitle}>
                  Add New Title
                </button>
              </div>
              <div className="add-button2">
                <button type="button" onClick={updateParameters}>
                  Update Parameters
                </button>
              </div>
            </div>

            <div
              className="parameters-grid"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              {testParameters.map((param, titleIndex) => (
                <div
                  key={param._id || `new-${titleIndex}`}
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="font-semibold">Parameter Title</div>
                    {/* <button
                      onClick={() => removeTitle(titleIndex)}
                      className="text-red-600 hover:text-red-800"
                      title="Remove Title"
                    >
                      <Trash2 size={18} />
                    </button> */}
                  </div>
                  <input
                    type="text"
                    value={param.title}
                    onChange={(e) => handleTitleChange(titleIndex, e.target.value)}
                    placeholder="Title"
                    className="form-control mb-2 ml-2 mt-2"
                  />

                  <div className="font-semibold">Parameters</div>
                  {param.parameters.map((parameter, paramIndex) => (
                    <div key={paramIndex} className="flex items-center gap-2">
                      <input
                        type="text"
                        value={parameter}
                        onChange={(e) =>
                          handleParameterChange(
                            titleIndex,
                            paramIndex,
                            e.target.value
                          )
                        }
                        placeholder="Parameter"
                        className="form-control mb-2 ml-2 flex-grow"
                        style={{ marginTop: "5px" }}
                      />
                      <button
                        onClick={() => removeParameter(titleIndex, paramIndex)}
                        className="text-red-600 hover:text-red-800 mb-2"
                        title="Remove Parameter"
                        disabled={param.parameters.length === 1}
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  ))}

                  <div className="add-button2 mt-2">
                    <button type="button" onClick={() => addParameter(titleIndex)}>
                      Add Parameter
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditTestParameter;