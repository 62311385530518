import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const WhyChooseUs = () => {
  const features = [
    {
      imgSrc: "images/stars.png",
      title: "5 Star Rating",
    },
    {
      imgSrc: "images/phone.png",
      title: "Report In 24 Hours",
    },
    {
      imgSrc: "images/Microscope.png",
      title: "Modern Machines",
    },
    {
      imgSrc: "images/doctor.png",
      title: "Certified Doctors",
    },
    {
      imgSrc: "images/doctor.png",
      title: "Certified Doctors",
    },
    {
      imgSrc: "images/doctor.png",
      title: "Certified Doctors",
    },
    {
      imgSrc: "images/doctor.png",
      title: "Certified Doctors",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center md:py-24 py-12 px-4 sm:px-8 lg:px-20 bg-grad max-w-screen-2xl mx-auto h-[501px] relative">
      <h2 className="text-2xl lg:text-3xl font-bold font-museo mb-12">
        Why Choose <span className="font1">Us?</span>
      </h2>

      <div className="w-full max-w-screen-2xl mx-auto relative">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".next-butt",
            prevEl: ".prev-butt",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {features.map((feature, index) => (
            <SwiperSlide key={index}>
              <div className="w-full h-40 bg-card1 rounded-xl flex flex-col items-center justify-center text-white p-4 max-w-screen-2xl">
                <img
                  src={feature.imgSrc}
                  alt={feature.title}
                  className="w-16 h-16"
                />
                <p className="mt-6 text-xl font-mont">{feature.title}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <div className="absolute left-1/2 -bottom-20 transform -translate-x-1/2 flex space-x-4 mb-4 md:mb-1">
          <div className="prev-butt p-3 rounded-full border border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all">
            <FiArrowLeft size={22} />
          </div>
          <div className="next-butt p-3 rounded-full border border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all">
            <FiArrowRight size={22} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
