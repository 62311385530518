import React from "react";

function Banner() {
  return (
    <div
      className="flex items-center justify-center bg-cover bg-center "
      style={{
        backgroundImage: 'url("images/Rectangle 5.png")',
        width: "100%",
        height: "381px",
      }}
    >
      {/* Header Text */}
      <div className="mt-8 sm:mt-12 flex flex-col justify-center items-center sm:text-left">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white font-museo leading-tight sm:leading-10">
        Blogs
        </h1>
      </div>
    </div>
  );
}

export default Banner;
