import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PiCalendarDotsDuotone } from "react-icons/pi";
import { BiSolidPhone } from "react-icons/bi";
import Sidebar from "./Sidebar";
import { format } from "date-fns";
import axiosClient from "../../axios-client";

function Navbar2() {
  const [selectedDate, setSelectedDate] = useState(null); // State for selected date
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // State to toggle datepicker visibility
  const datePickerRef = useRef(null); // Ref for date picker
  const [info, setInfo] = useState();
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axiosClient.get("details");

        if (response.data.success) {
          setInfo(response.data.generalDetails);
          // console.log("Info",response.data.generalDetails)
        } else {
          throw new Error("Failed to fetch info.");
        }
      } catch (err) {
        console.error(err.message || "Failed to fetch info. Please try again.");
      }
    };

    fetchInfo();
  }, []);


  // Function to toggle date picker
  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsDatePickerOpen(false); // Close date picker when a date is selected
  };

  // Close the date picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-primary1 text-white  h-[75px] py-4 max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-12 lg:px-20 flex flex-wrap justify-between items-center">
      <div className="flex items-center">
        <Sidebar />
        <button
          onClick={toggleDatePicker}
          className="text-white flex items-center px-3 py-1 rounded-md ml-2 sm:ml-5"
        >
          <PiCalendarDotsDuotone />
          <span className="ml-2 text-sm sm:text-base font-museo font-medium">
            {selectedDate
              ? format(selectedDate, "dd MMMM, yyyy")
              : "Book a Test"}
          </span>
        </button>

        {/* Date Picker Dropdown */}
        {isDatePickerOpen && (
          <div
            className="absolute mt-20 sm:mt-80 z-10 bg-white p-2 rounded shadow-lg"
            ref={datePickerRef}
          >
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              inline
            />
          </div>
        )}
      </div>

      <div className="flex items-center space-x-2 mt-1 sm:my-0 font-jost">
        <BiSolidPhone />
        <span className="text-sm sm:text-base">
          <a href={`tel:${info?.phoneNumber}`}>{info?.phoneNumber}</a>
        </span>
      </div>
    </nav>
  );
}

export default Navbar2;
