import React, { useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Banner from "./FAQBanner";
import "../../../src/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />

      {/* Main content container with responsive design */}
      <div className="flex flex-col md:flex-row justify-center items-start max-w-7xl mx-auto mt-20 px-4 md:px-8 lg:px-10 gap-y-10 md:gap-x-14">
        {/* Left Section with Gradient */}
        <div className="w-full md:w-2/3 lg:w-1/4 bg-gradient-to-b from-[#2E3192] to-[#63D1F3] flex flex-col items-center text-white p-2  rounded-l-[29px] rounded-tr-[29px] rounded-br-[29px]">
          <img
            src={`${process.env.PUBLIC_URL}/images/faq.png`}
            alt="Help Icon"
            className="mb-3 w-full max-w-xs md:max-w-l h-auto rounded-full" // Adjusted max-w here
          />
          <div className="text-center w-full">
            <h2 className="text-xl font-bold mb-2 font-mont">
              Still no luck? We can help!
            </h2>
            <p className="mb-4 font-mont">
              Contact us and we’ll get back to you as soon as possible.
            </p>
          </div>
          <div className="flex justify-center w-full">
            <Link to="/contact">
              <button className="bg-red-500 text-white py-2 px-10 rounded-full font-mont w-full md:w-auto mb-2">
                SUBMIT A REQUEST
              </button>
            </Link>
          </div>
        </div>

        {/* Right Section with FAQ */}
        <div className="w-full md:w-2/3 bg-white mb-10">
          <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
            Frequently Asked Questions
          </h2>
          <div className="space-y-6 mb-20">
            {/* First FAQ Item */}
            <div
              className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                activeIndex === 0
                  ? "bg-[#1E4FA6] text-white"
                  : "bg-[#42424280] text-white p-2"
              }`}
            >
              <button
                className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                  activeIndex === 0 ? "bg-[#1E4FA6] text-white" : "text-white"
                }`}
                onClick={() => toggleFAQ(0)}
              >
                <span className="font-medium font-museo">
                  What is the mission of your diagnostics platform?
                </span>
                <span className="arrow-icon">
                  <div
                    className={`transform ${
                      activeIndex === 0 ? "rotate-180" : "rotate-0"
                    } transition-transform duration-300`}
                  >
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      style={{ color: "#e5e7eb" }}
                    />
                  </div>
                </span>
              </button>
              {activeIndex === 0 && (
                <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                  Our mission is to make high-quality, affordable diagnostic
                  services accessible to everyone in Bharat, especially in
                  underserved and remote areas...
                </div>
              )}
            </div>

            {/* Other FAQ Items */}
            {[...Array(4)].map((_, index) => (
              <div
                key={index + 1}
                className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                  activeIndex === index + 1
                    ? "bg-[#1E4FA6] text-white"
                    : "bg-[#42424280] text-white"
                }`}
              >
                <button
                  className={`w-full text-left p-6 focus:outline-none flex items-center justify-between ${
                    activeIndex === index + 1
                      ? "bg-[#1E4FA6] text-white"
                      : "text-white"
                  }`}
                  onClick={() => toggleFAQ(index + 1)}
                >
                  <span className="font-medium font-museo">
                    How can I get access key for the API's provided by the
                    platform?
                  </span>
                  <span className="arrow-icon">
                    <div
                      className={`transform ${
                        activeIndex === 0 ? "rotate-0" : "rotate-180"
                      } transition-transform duration-300`}
                    >
                      <FontAwesomeIcon
                        icon={
                          activeIndex === index + 1 ? faAngleUp : faAngleDown
                        }
                        style={{ color: "#e5e7eb" }}
                      />
                    </div>
                  </span>
                </button>
                {activeIndex === index + 1 && (
                  <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                    To get access to the API keys, please contact our support
                    team or visit...
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <NewsLetter />
      <Footer />
    </>
  );
}

export default FAQ;
